import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_sidebar-layout/tenant/$tenantId/')({
  beforeLoad: ({ params }) => {
    throw redirect({
      to: '/tenant/$tenantId/estates',
      params,
    });
  },
});
