import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Copy, TrashIcon, X } from 'lucide-react';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { env } from '@/constants/env';
import { queryClient } from '@/lib/react-query';

import {
  getDigitalTwinsOptions,
  type PresignedUrl,
  useShareDigiTwin,
} from '../../api/get-digital-twins';
import { useRevokePresignedUrl } from '../../api/presigned-urls';

dayjs.extend(localizedFormat);

export interface ShareDigiTwinModalProps {
  estateId: string;
  id: string;
  planId: string;
  presignedUrls: PresignedUrl[];
}

export const ShareDigiTwinModal: React.FC<ShareDigiTwinModalProps> = ({
  estateId,
  id,
  planId,
  presignedUrls,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expiresIn, setExpiresIn] = useState<number | undefined>(60);
  // necessary to correctly remove a deleted link without closing the modal
  const [displayedLinks, setDisplayedLinks] = useState(presignedUrls);
  const [editingOption, setEditingOption] = useState<'view' | 'limited'>('view');
  const { t } = useTranslation();

  const { mutate: shareDigiTwin } = useShareDigiTwin({
    mutationConfig: {
      onSuccess: ([{ token, expiryDate }]) => {
        const url = `${env.PUBLIC_EDITOR_URL}/${editingOption}?token=${token}`;
        navigator.clipboard.writeText(url);
        toast.success(t('copied-url'));
        setDisplayedLinks((prev) => [...prev, { token, expiryDate }]);
        setIsDropdownOpen(false);
        queryClient.invalidateQueries({ queryKey: getDigitalTwinsOptions(estateId).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const { mutate: revokeToken } = useRevokePresignedUrl({
    mutationConfig: {
      onSuccess: ([{ token }]) => {
        toast.success(t('successfully-revoked-link'));
        setDisplayedLinks(displayedLinks.filter((l) => l.token !== token));
        queryClient.invalidateQueries({ queryKey: getDigitalTwinsOptions(estateId).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const handleRevoke = (token: string) => {
    revokeToken({ token });
  };

  const createNewPresignedUrl = () => {
    if (!id) return;
    shareDigiTwin({
      id,
      planId,
      expiresIn,
    });
  };

  return (
    <ModalBase className="w-[40vw]">
      <X className="ml-auto cursor-pointer stroke-[4px] text-primary-light" onClick={closeModal} />
      <section className="mt-4 flex flex-row justify-between">
        <h3 className="text-xl font-semibold">Links</h3>
        <DropdownMenu open={isDropdownOpen}>
          <DropdownMenuTrigger onClick={() => setIsDropdownOpen(true)} asChild>
            <Button>Create new link</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="px-3 py-4">
            <div className="flex flex-col">
              <Input
                label={t('expires-in')}
                value={expiresIn}
                type="number"
                onChange={(e) => setExpiresIn(parseInt(e.target.value))}
                className="mb-3"
              />
              <span className="mb-1 text-xs text-text-secondary">{t('editing-mode')}</span>
              <div className="flex flex-col gap-2 rounded-md border-2 border-primary-light">
                <ToggleGroup
                  type="single"
                  value={editingOption}
                  onValueChange={(value) => setEditingOption(value as 'view' | 'limited')}
                  aria-label="Options"
                >
                  <ToggleGroupItem value="view">{t('view-only')}</ToggleGroupItem>
                  <ToggleGroupItem value="limited">{t('limited')}</ToggleGroupItem>
                </ToggleGroup>
              </div>
            </div>
            <div className="mt-4 flex flex-row gap-2">
              <Button variant="contained" onClick={createNewPresignedUrl}>
                {t('share')}
              </Button>
              <Button variant="outline" onClick={() => setIsDropdownOpen(false)}>
                {t('close')}
              </Button>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </section>
      {displayedLinks.length > 0 ? (
        <Table className="my-5 w-full table-auto bg-transparent">
          <TableHeader className="h-11 max-sm:hidden">
            <TableRow className="w-full justify-between">
              <TableHead className="text-center text-text-secondary">Link</TableHead>
              <TableHead className="text-center text-text-secondary">{t('expires-at')}</TableHead>
              <TableHead className="text-center text-text-secondary"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {displayedLinks.map((presignedUrl) => {
              const url = `${env.PUBLIC_EDITOR_URL}/view?token=${presignedUrl.token}`;

              if (dayjs().isAfter(presignedUrl.expiryDate)) return;

              return (
                <TableRow key={presignedUrl.token} className="items-center text-primary-dark">
                  <TableCell className="min-w-min py-5 text-center">
                    <a className="no-underline" href={url} target="__blank">
                      {url}
                    </a>
                  </TableCell>
                  <TableCell className="max-w-40 whitespace-normal break-words py-5 text-center lg:w-1/4">
                    {dayjs(presignedUrl.expiryDate).format('L')}
                  </TableCell>
                  <TableCell>
                    <div className="hidden w-auto items-center justify-end gap-1 py-5 text-center lg:grid lg:grid-cols-2 xl:flex">
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                          toast.success(t('copied-url'));
                        }}
                        variant="outline"
                        className="border-2"
                      >
                        <Copy aria-hidden />
                        <span className="sr-only">{t('copy')}</span>
                      </Button>
                      <Button
                        className="max-xl:max-w-[60px]"
                        onClick={() => handleRevoke(presignedUrl.token)}
                        variant="destructive"
                      >
                        <TrashIcon aria-hidden />
                        <span className="sr-only"> {t('delete')}</span>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <div className="flex justify-center py-16">
          <span>{t('noDocuments')}</span>
        </div>
      )}
    </ModalBase>
  );
};
