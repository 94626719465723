import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { RegisterPage } from '@/pages/register';
import { checkTokenExistence } from '@/utils/checkTokenExistence';

export const Route = createFileRoute('/_auth-layout/cm416ujis000008jzbr1q4f86')({
  validateSearch: zodSearchValidator(
    z.object({
      token: z.string().optional(),
    })
  ),
  component: RegisterPage,
  beforeLoad: async ({ search }) => {
    // we could also redirect to a different page in this case, then we could call this route `register` again
    if (!search.token) return;

    if (!(await checkTokenExistence(search.token))) {
      throw redirect({ to: '/token-error' });
    }
  },
});
