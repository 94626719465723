import { useTranslation } from 'react-i18next';

import { getRenderValue } from '@/utils/getEmptyVisualValue';
import { getPriceWithCurrency } from '@/utils/getPriceWithCurrency';

import { Estates } from '../api/estates';
import { EstatesInfiniteScroll } from './estates-infinite-scroll';
import { EstatesListCard } from './estates-list-card';

interface EstatesListProps {
  data: Estates;
  filters?: object;
  filterOwn?: boolean;
}

export const EstatesList: React.FC<EstatesListProps> = ({ data, filters, filterOwn }) => {
  const { i18n } = useTranslation();

  return (
    <ul className="mb-[30px] flex flex-col gap-[27px] max-xl:gap-4">
      {data.map((estate) => (
        <EstatesListCard
          key={estate.id}
          status={estate.status}
          id={estate.id}
          livingArea={estate.estateAreasRooms?.livingArea}
          externalId={estate.estateDetails?.externalId}
          name={estate.name}
          price={getPriceWithCurrency(
            estate.prices?.buyingPrice,
            estate.prices?.currency,
            i18n.language
          )}
          roomsCount={estate.estateAreasRooms?.totalNumberOfRooms}
          createdAt={estate.createdAt}
          creator={`${getRenderValue(estate.creator?.firstName)} ${getRenderValue(estate.creator?.lastName)}`}
          thumbnailId={estate.thumbnail?.mediaId || ''}
        />
      ))}
      <EstatesInfiniteScroll Card={EstatesListCard} filters={filters} filterOwn={filterOwn} />
    </ul>
  );
};
