import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

type MyTenantsQueryOptions = {
  queryConfig?: QueryConfig<typeof getMyTenantsQueryOptions>;
};

export type MyTenants = Awaited<ReturnType<typeof getMyTenants>>;

export const getMyTenants = () => {
  return extractData(api.users.tenant.get());
};

export const getMyTenantsQueryOptions = () => {
  return queryOptions({
    queryKey: ['tenant', 'me'],
    queryFn: getMyTenants,
  });
};

export const ensureMyTenantsQueryData = ({ context: { queryClient } }: LoaderProps) => {
  return queryClient.ensureQueryData(getMyTenantsQueryOptions());
};

export const useMyTenants = ({ queryConfig }: MyTenantsQueryOptions = {}) => {
  return useSuspenseQuery({ ...getMyTenantsQueryOptions(), ...queryConfig });
};
