import { useTranslation } from 'react-i18next';

import { getRouteApi, Link } from '@tanstack/react-router';

import { Form } from '@/components/form/form';
import { FormCheckbox } from '@/components/form/form-checkbox';
import { FormInput } from '@/components/form/form-input';
import { SubmitButton } from '@/components/form/submit-button';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

import { useSignUp } from '../api/register';
import { RegistrationSchema, registrationSchema } from '../schemas/credentials';

const defaultValues: RegistrationSchema = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  isTosAccepted: false,
  isPrivacyAccepted: false,
};

const route = getRouteApi('/_auth-layout/cm416ujis000008jzbr1q4f86');

export const RegisterForm = () => {
  const { token } = route.useSearch();
  const { signup, isLoading, error } = useSignUp(token);
  const { t } = useTranslation();

  return (
    <Form
      aria-label={t('register-form')}
      schema={registrationSchema}
      onSubmit={signup}
      defaultValues={defaultValues}
    >
      {error && (
        <Alert variant="destructive" className="mb-5">
          <AlertTitle>{t('registration-error-title')}</AlertTitle>
          <AlertDescription>{t('registration-error-description')}</AlertDescription>
        </Alert>
      )}
      <fieldset className="flex flex-col gap-4">
        <FormInput label={t('firstName')} type="text" name="firstName" />
        <FormInput label={t('lastName')} type="text" name="lastName" />
        <FormInput label={t('email')} type="email" name="email" />
        <FormInput label={t('password')} type="password" name="password" />
        <fieldset className="flex flex-col gap-1">
          <FormCheckbox id="terms" name="isTosAccepted" label={t('tos')} />
          <FormCheckbox id="privacy" name="isPrivacyAccepted" label={t('privacy')} />
        </fieldset>
        <SubmitButton loading={isLoading}>{t('register')}</SubmitButton>
        <div className="text-center">
          <p className="text-sm text-text-secondary">
            {t('got-account-question')}{' '}
            <Link to="/login" className="underline">
              {t('login')}
            </Link>
          </p>
        </div>
      </fieldset>
    </Form>
  );
};
