import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

type RevokePresignedUrlOptions = {
  mutationConfig?: MutationConfig<typeof revokePresignedUrl>;
};

const revokePresignedUrl = ({ token }: { token: string }) => {
  return extractData(api.token({ token }).delete());
};

export const useRevokePresignedUrl = ({ mutationConfig }: RevokePresignedUrlOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: revokePresignedUrl });
};
