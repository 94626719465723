import { z } from 'zod';

export const employeeRoles = ['admin', 'back office', 'supervisor', 'agent'] as const;

export const getEmployeeRoleSchema = () =>
  z.object({
    role: z.enum(employeeRoles),
  });

export type EmployeeRoleType = z.infer<ReturnType<typeof getEmployeeRoleSchema>>;
