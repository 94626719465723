import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Share2Icon } from 'lucide-react';

import { Button, type ButtonProps } from '@/components/ui/button';

type EstateShareButtonProps = ButtonProps & {
  textToCopy: string;
};

export const CopyButton = ({ textToCopy, ...props }: EstateShareButtonProps) => {
  const [t] = useTranslation();
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(t('copied', { autoClose: 100 }));
      })
      .catch(() => {
        toast.success(t('failed to copy', { autoClose: 1000 }));
      });
  };

  return (
    <Button
      type="submit"
      onClick={onClick}
      className="border-2 bg-primary-lighter/80"
      variant="outline"
      size="md"
      rounded
      {...props}
    >
      <Share2Icon />
    </Button>
  );
};
