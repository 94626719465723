import { z } from 'zod';

const envSchema = z.object({
  API_URL: z.string().default('http://localhost:8787'),
  PUBLIC_EDITOR_URL: z.string().default('https://editor.realitiq.com'),
  PUBLIC_POSTHOG_KEY: z.string().default('phc_i5iQaRykacmNyHrWz9K9dVKhTeuuIwj7UP4TuZCa2Xe'),
  PUBLIC_POSTHOG_HOST: z.string().default('https://eu.i.posthog.com'),
  PRIMARY_COLOR_LIGHTER: z.string().default('0 0% 100%'),
  PRIMARY_COLOR_LIGHT: z.string().default('184 93% 39%'),
  PRIMARY_COLOR: z.string().default('193 62% 28%'),
  PRIMARY_COLOR_DARKER: z.string().default('218 9% 18%'),
  PRIMARY_COLOR_DARK: z.string().default('192 100% 13%'),
  BACKGROUND_COLOR_LIGHTER: z.string().default('0 0% 93%'),
  BACKGROUND_COLOR_DARKER: z.string().default('0 0% 85%"'),
  TEXT_COLOR_PRIMARY: z.string().default('199 72% 8%'),
  TEXT_COLOR_SECONDARY: z.string().default('0 0% 60%'),
  SUCCESS_COLOR: z.string().default('173 93% 39%'),
  WARNING_COLOR: z.string().default('65 83% 54%'),
  CAUTION_COLOR: z.string().default('40 84% 49%'),
  DANGER_COLOR: z.string().default('0 93% 39%'),
  COMPANY_NAME: z.string().default('RelityIq'),
  LOGO_URL: z.string().default('/logo.svg'),
  MAP_BOX_API_TOKEN: z
    .string()
    .default(
      'pk.eyJ1IjoiZGV2YmluNzc3IiwiYSI6ImNtM2cwMG9wOTAxZHQyanFxMmllaXZpdTEifQ.DDX1-1HXW7lvfO1nwMlFnQ'
    ),
});

export const parseEnv = (env = import.meta.env) => {
  const envVars = Object.entries(env).reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr;

    if (key.startsWith('VITE_')) {
      acc[key.replace('VITE_', '')] = value;
    }

    return acc;
  }, {});

  const parsedEnv = envSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided. The following variables are missing or invalid: ${Object.entries(
        parsedEnv.error.flatten().fieldErrors
      )
        .map(([key, value]) => `- ${key}: ${value}`)
        .join('\n')}
      `
    );
  }

  return parsedEnv.data;
};
