import { createFileRoute } from '@tanstack/react-router';

import { TenantSection } from '@/pages/tenant-section';

export const Route = createFileRoute('/_authenticated/_sidebar-layout/tenant/$tenantId/subtenants')(
  {
    component: RouteComponent,
  }
);

// eslint-disable-next-line react/function-component-definition
function RouteComponent() {
  const { tenantId } = Route.useParams();

  return <TenantSection active="subtenants" id={tenantId} />;
}
