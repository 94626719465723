import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteScroll } from '@/hooks/use-infinite-scroll';
import { getRenderValue } from '@/utils/getEmptyVisualValue';
import { getPriceWithCurrency } from '@/utils/getPriceWithCurrency';

import { Estates, getEstates } from '../api/estates';
import { EstateAdditionalExtendedInfoProps } from './estate-extended-info';
import { EstateCardProps } from './estate-grid-card';

export type EstateLongCardProps = EstateCardProps &
  EstateAdditionalExtendedInfoProps & { thumbnailId: string };

interface EstatesInfiniteScrollProps {
  Card: FC<EstateLongCardProps>;
  filters?: object;
  filterOwn?: boolean;
}

interface EstatesState {
  [key: string]: Estates[number];
}

export const EstatesInfiniteScroll: FC<EstatesInfiniteScrollProps> = ({
  Card,
  filters,
  filterOwn,
}) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [stopLoad, setStopLoad] = useState(false);
  const [estates, setEstates] = useState<EstatesState | null>(null);

  const isNotEmptyFilters = filters && Object.keys(filters).length;

  const { lastElementRef, pageNum, resetPageNum } = useInfiniteScroll<HTMLDivElement>({
    loading,
    stopLoad,
    startPage: 2,
  });

  const loadMoreEstates = async (page: number) => {
    setLoading(true);
    try {
      const estates = await getEstates({
        query: {
          page,
          filterOwn: filterOwn,
          ...(isNotEmptyFilters && { filters }),
        },
      });
      if (!estates.length) return setStopLoad(true);

      const estatesCollection = estates.reduce((acc, v) => {
        return {
          ...acc,
          [v.id]: v,
        };
      }, {});
      setEstates((prev) =>
        prev
          ? {
              ...prev,
              ...estatesCollection,
            }
          : estatesCollection
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreEstates(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  useEffect(() => {
    setEstates(null); // Reset the estates when filters change
    resetPageNum();
    setStopLoad(false);
    loadMoreEstates(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Fragment>
      {estates &&
        Object.keys(estates).map((id) => {
          const estate = estates?.[id];
          return (
            <div key={estate.id} ref={lastElementRef}>
              <Card
                status={estate.status}
                id={estate.id}
                address={estate.address}
                livingArea={estate.estateAreasRooms?.livingArea}
                name={estate.name}
                price={getPriceWithCurrency(
                  estate.prices?.buyingPrice,
                  estate.prices?.currency,
                  i18n.language
                )}
                roomsCount={estate.estateAreasRooms?.totalNumberOfRooms}
                createdAt={estate.createdAt}
                creator={`${getRenderValue(estate.creator?.firstName)} ${getRenderValue(estate.creator?.lastName)}`}
                thumbnailId={estate.thumbnail?.mediaId || ''}
              />
            </div>
          );
        })}
    </Fragment>
  );
};
