import { useTranslation } from 'react-i18next';

import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { formatDate } from '@/utils/formatDate';

import { TenantEmployees } from '../api/tenant-users';
import { EmployeeRoleType } from '../schema/employee-role';
import { AddEmployeeButton } from './add-employee-button';
import { DeleteEmployeeButton } from './delete-employee-button';
import { EditEmployeeButton } from './edit-employee-button';

interface TenantEmployeeListProps {
  employees: TenantEmployees;
  tenantId: string;
}

export const TenantEmployeeList = ({ employees, tenantId }: TenantEmployeeListProps) => {
  const { t } = useTranslation();

  return (
    <div className="size-full">
      <Card className="px-4 py-6 lg:px-10">
        <div className="flex justify-between">
          <div>
            <h2 className="text-md text-primary-dark">{t('team')}</h2>
          </div>
          <AddEmployeeButton tenantId={tenantId} />
        </div>
        {employees.length ? (
          <Table className="my-5 w-full table-auto bg-transparent">
            <TableHeader className="h-11 max-sm:hidden">
              <TableRow className="w-full justify-between">
                <TableHead className="text-center text-text-secondary">{t('name')}</TableHead>
                <TableHead className="text-center text-text-secondary">{t('email')}</TableHead>
                <TableHead className="text-center text-text-secondary max-md:hidden">
                  {t('created-at')}
                </TableHead>
                <TableHead className="text-center text-text-secondary">{t('role')}</TableHead>
                <TableHead className="text-center text-text-secondary">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {employees.map((employee) => (
                <TableRow
                  key={employee.user.id}
                  className="cursor-pointer items-center text-primary-dark"
                >
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center">
                    {`${employee.user.firstName} ${employee.user.lastName}`}
                  </TableCell>
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center">
                    {employee.user.email}
                  </TableCell>
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center max-md:hidden">
                    {formatDate(employee.createdAt)}
                  </TableCell>
                  <TableCell className="max-w-40 whitespace-normal break-words py-5 text-center lg:w-1/4">
                    {t(employee.role.replaceAll(' ', '-'))}
                  </TableCell>
                  <TableCell className="flex max-w-40 gap-2 lg:w-1/4">
                    <EditEmployeeButton
                      id={employee.user.id}
                      role={employee.role as EmployeeRoleType['role']}
                      tenantId={tenantId}
                    />
                    <DeleteEmployeeButton id={employee.user.id} tenantId={tenantId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex justify-center py-16">
            <span>{t('no-team')}</span>
          </div>
        )}
      </Card>
    </div>
  );
};
