import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import Spinner from '@/components/ui/spinner';
import { i18n } from '@/lib/i18n';

import { useDuplicateDigitalTwin } from '../api/copy-digital-twin';
import { getDigitalTwinsQueryOptions } from '../api/get-digital-twins';

export interface Copy3DModelModalProps {
  confirmationText?: string;
  digiTwin: { name: string; id: string };
  estateId: string;
}

const copyFormData = () =>
  z.object({
    title: z.string().min(1, i18n.t('required-field', { field: i18n.t('title') })),
  });

type Copy3DModelModalType = z.infer<ReturnType<typeof copyFormData>>;

export const Copy3DModelModal: React.FC<Copy3DModelModalProps> = ({ digiTwin, estateId }) => {
  const { t } = useTranslation();

  const methods = useForm<Copy3DModelModalType>({
    resolver: zodResolver(copyFormData()),
    defaultValues: { title: `${digiTwin.name}-copy` },
  });

  const { handleSubmit, control } = methods;
  const [loading, setLoading] = React.useState(false);
  const queryClient = useQueryClient();

  const { mutate: duplicateDigitalTwin } = useDuplicateDigitalTwin({
    mutationConfig: {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: getDigitalTwinsQueryOptions(estateId).queryKey,
        }),
      onError: () => toast.error(t('failed-to-duplicate-digital-twin')),
    },
  });

  const onSubmit = async (data: Copy3DModelModalType) => {
    try {
      setLoading(true);
      duplicateDigitalTwin({ id: digiTwin.id, name: data.title });
      closeModal();
    } catch {
      toast.error(t('failed-to-duplicate-digital-twin'));
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase className="w-full max-w-96">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-primary-lighter/40">
              <Spinner className="size-12 [&_*]:stroke-primary-dark" />
            </div>
          )}
          <Controller
            name="title"
            control={control}
            render={({ field, formState }) => (
              <Input
                {...field}
                size="lg"
                label={t('title')}
                errors={[formState.errors.title?.message || '']}
              />
            )}
          />
          <div className="mt-8 grid grid-cols-2 gap-4">
            <Button onClick={closeModal} variant="outline" className="border-red-500 text-red-500">
              {t('cancel')}
            </Button>
            <Button type="submit">{t('submit')}</Button>
          </div>
        </form>
      </FormProvider>
    </ModalBase>
  );
};
