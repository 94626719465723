import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type TenantEstates = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.tenants>['estates']['get']>>['data']
>;

type TenantInstance = ReturnType<typeof api.tenants>;
type TenantQueryFilters = Parameters<TenantInstance['estates']['get']>;

type TenantId = TenantEstates[number]['id'];

type TenantEstateQueryOptions = {
  queryConfig?: QueryConfig<typeof getTenantEstates>;
  id: TenantId;
  args: TenantQueryFilters;
};

const getTenantEstates = (id: TenantId, ...args: TenantQueryFilters) => {
  return extractData(api.tenants({ id }).estates.get(...args));
};

export const getTenantEstateOptions = (id: TenantId, ...args: TenantQueryFilters) => ({
  queryKey: ['tenants', id, 'estates', ...args],
  queryFn: () => getTenantEstates(id, ...args),
});

export const getTenantEstateQueryOptions = (id: TenantId, ...args: TenantQueryFilters) => {
  return queryOptions(getTenantEstateOptions(id, ...args));
};

export const ensureTenantEstateQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getTenantEstateQueryOptions(params.tenantId, { query: {} }));
};

export const useTenantEstates = ({ queryConfig, id, args }: TenantEstateQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getTenantEstateQueryOptions(id, ...args) });
};
