import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { UseNavigateResult } from '@tanstack/react-router';

import { useMyTenants } from '@/api/tenant';
import { useCreateEstate } from '@/features/estates/api/estates';
import { EstateForm } from '@/features/estates/components/estate-form';
import { type EstateSchemaType, getCreateEstateSchema } from '@/features/estates/schemas/estate';

import { ModalBase } from './modals-base';

export interface CreateEstateModalProps {
  navigate: UseNavigateResult<string>;
}

export const CreateEstateModal: React.FC<CreateEstateModalProps> = ({ navigate }) => {
  const schema = getCreateEstateSchema();
  const { t } = useTranslation();
  const { data: tenants } = useMyTenants();

  const { mutateAsync } = useCreateEstate({
    mutationConfig: {
      onSuccess: ([createdEstate]) => {
        navigate({ to: `/estates/${createdEstate.id}` });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });
  const methods = useForm<EstateSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      estateData: {
        name: '',
        propertyType: '',
        marketType: '',
        tenantId: tenants[0].id ?? '',
      },
      addressData: {
        address: '',
        postalCode: '',
        city: '',
        countryId: '',
      },
    },
  });

  const onSubmit = async (args: EstateSchemaType) => {
    await mutateAsync(args);
  };

  return (
    <ModalBase>
      <FormProvider {...methods}>
        <EstateForm label={t('create-estate')} onSubmit={onSubmit} />
      </FormProvider>
    </ModalBase>
  );
};
