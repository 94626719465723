import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Search, X } from 'lucide-react';

import { useCountriesOptions } from '@/api/countries';
import { useEstateOptions } from '@/api/estate-options';
import { useMyTenants } from '@/api/tenant';
import { closeModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Map } from '@/components/ui/map';
import { Select } from '@/components/ui/select';
import Spinner from '@/components/ui/spinner';

import type { EstateSchemaType } from '../schemas/estate';
import { AddressAutocomplete, OnSelectSuggestion } from './address-autocomplete';

interface EstateFormProps {
  label: string;
  onCancel?: () => void;
  onSubmit: (data: EstateSchemaType) => Promise<void> | void;
}

export const EstateForm: React.FC<EstateFormProps> = ({ label, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const { data: countries } = useCountriesOptions();
  const { data } = useEstateOptions();
  const { data: tenants } = useMyTenants();

  const tenantOptions = useMemo(
    () =>
      tenants.map((tenant) => ({
        title: tenant.name,
        id: tenant.id,
      })),
    [tenants]
  );

  const { handleSubmit, watch, reset, control } = useFormContext<EstateSchemaType>();

  const latitude = watch('addressData.lat');
  const longitude = watch('addressData.long');

  const [loading, setLoading] = useState(false);

  const onSubmitHandle = handleSubmit(async (data) => {
    try {
      setLoading(true);
      await onSubmit(data);
      toast.success(t('create-estate-success'));
    } finally {
      setLoading(false);
    }
    return closeModal();
  });

  const onSelectSuggestion: OnSelectSuggestion = ({
    city,
    address,
    latitude,
    longitude,
    countryId,
    postalCode,
    countryCode,
  }) => {
    reset((prev) => ({
      ...prev,
      addressData: {
        ...prev.addressData,
        ...(address && { addressAutoComplete: address }),
        city: city ?? '',
        postalCode: postalCode ?? '',
        ...(address && { address }),
        ...(countryId && { countryId }),
        ...(latitude && { lat: latitude }),
        ...(longitude && { long: longitude }),
        ...(countryCode && { countryCode }),
      },
    }));
  };
  const onCancelHandle = () => {
    onCancel?.();
    return closeModal();
  };

  return (
    <form
      className="relative flex w-screen max-w-screen-sm flex-col gap-2 overflow-auto"
      onSubmit={onSubmitHandle}
    >
      <X className="ml-auto cursor-pointer stroke-[4px] text-primary-light" onClick={closeModal} />
      {loading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      <p className="text-center">{label}</p>
      <div className="no-scrollbar flex max-h-[34rem] flex-col gap-2 overflow-y-scroll">
        <div className="w-full rounded-md border border-background-lighter">
          <p className="w-full rounded-t-md bg-background-lighter px-10 py-2">{t('general')}</p>
          <div className="px-10 pb-8 pt-5">
            <Controller
              name="estateData.name"
              control={control}
              render={({ field, formState }) => (
                <Input
                  {...field}
                  size="lg"
                  label={t('name')}
                  errors={[formState.errors.estateData?.name?.message || '']}
                />
              )}
            />
            <div className="grid grid-cols-2 gap-10">
              <Controller
                name="estateData.propertyType"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    {...field}
                    label={t('propertyType')}
                    options={data?.propertyType || []}
                    errors={[formState.errors.estateData?.propertyType?.message || '']}
                  />
                )}
              />
              <Controller
                name="estateData.marketType"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    {...field}
                    label={t('marketingType')}
                    options={data?.buyType || []}
                    errors={[formState.errors.estateData?.marketType?.message || '']}
                  />
                )}
              />
            </div>
            {tenantOptions.length ? (
              <Controller
                name="estateData.tenantId"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    {...field}
                    label={t('tenant')}
                    options={tenantOptions || []}
                    errors={[formState.errors.estateData?.tenantId?.message || '']}
                  />
                )}
              />
            ) : null}
          </div>
        </div>

        <div className="z-50 w-full rounded-md border border-background-lighter">
          <p className="w-full rounded-t-md bg-background-lighter px-10 py-2">{t('address')}</p>
          <div className="px-10 pb-8 pt-5">
            <Controller
              name="addressData.addressAutoComplete"
              control={control}
              render={({ field, formState }) => (
                <AddressAutocomplete
                  {...field}
                  size="lg"
                  startIcon={<Search color="#9CA3AF" />}
                  label={t('address')}
                  onSelectSuggestion={onSelectSuggestion}
                  errors={[formState.errors.addressData?.addressAutoComplete?.message || '']}
                />
              )}
            />
            <div className="mt-4 size-full h-48 overflow-hidden rounded bg-black">
              <Map
                zoom={12}
                {...(latitude &&
                  longitude && {
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                  })}
              />
            </div>
            <div className="mt-5 grid grid-cols-2 gap-x-10">
              <Controller
                name="addressData.address"
                control={control}
                render={({ field, formState }) => (
                  <Input
                    {...field}
                    size="lg"
                    label={t('street')}
                    errors={[formState.errors.addressData?.address?.message || '']}
                  />
                )}
              />
              <Controller
                name="addressData.postalCode"
                control={control}
                render={({ field, formState }) => (
                  <Input
                    {...field}
                    size="lg"
                    label={t('postalCode')}
                    errors={[formState.errors.addressData?.postalCode?.message || '']}
                  />
                )}
              />
              <Controller
                name="addressData.countryId"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    {...field}
                    size="lg"
                    label={t('country')}
                    options={countries || []}
                    errors={[formState.errors.addressData?.countryId?.message || '']}
                  />
                )}
              />
              <Controller
                name="addressData.city"
                control={control}
                render={({ field, formState }) => (
                  <Input
                    {...field}
                    size="lg"
                    label={t('city')}
                    errors={[formState.errors.addressData?.city?.message || '']}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-2 gap-1">
        <Button variant="outline" color="primary" onClick={onCancelHandle}>
          {t('cancel')}
        </Button>
        <Button type="submit">{t('submit')}</Button>
      </div>
    </form>
  );
};
