import { CopyButton } from '@/components/ui/copy-button';
import { cn } from '@/lib/utils';

type EstateActionsProps = React.ComponentProps<'div'>;

export const EstateActions = ({ className, ...props }: EstateActionsProps) => {
  return (
    <div className={cn('flex gap-[10px]', className)} {...props}>
      <CopyButton textToCopy="" />
    </div>
  );
};
