import { useFormContext } from 'react-hook-form';

import { Button, type ButtonProps } from '@/components/ui/button';

export const SubmitButton = ({ disabled, loading, ...props }: ButtonProps) => {
  const {
    formState: { isSubmitting, isSubmitted, isValid },
  } = useFormContext();

  return (
    <Button
      type="submit"
      {...props}
      loading={isSubmitting || loading}
      disabled={disabled || (isSubmitted && !isValid) || isSubmitting}
    />
  );
};
