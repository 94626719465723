import { z } from 'zod';

import { employeeRoles } from './employee-role';

export const getAddEmployeeSchema = () =>
  z.object({
    role: z.enum(employeeRoles),
    email: z.string().email(),
  });

export type AddEmployeeType = z.infer<ReturnType<typeof getAddEmployeeSchema>>;
