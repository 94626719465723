import { useTranslation } from 'react-i18next';

import { useLocation } from '@tanstack/react-router';
import { FolderUpIcon, HomeIcon, LayoutDashboardIcon, User2Icon } from 'lucide-react';

import { SidebarLink } from '@/components/ui/sidebar-link';

import { TenantSidebarLink } from './tenant-sidebar-link';

type SidebarProps = {
  isVisible: boolean;
  closeMobileSidebarMenu: () => void;
};

export const Sidebar = ({ isVisible, closeMobileSidebarMenu }: SidebarProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  return (
    <aside
      className={`${
        isVisible
          ? 'z-50 px-2 ease-linear max-md:absolute max-md:left-0 max-md:top-[100px] max-md:flex max-md:h-[calc(100vh-100px)] max-md:w-full max-md:translate-x-0 max-md:bg-white'
          : 'max-lg:absolute max-lg:left-0 max-lg:top-[100px] max-lg:-translate-x-full'
      } w-full max-w-[273px] flex-col bg-transparent transition-all duration-200 lg:flex`}
    >
      <nav className="flex flex-col gap-1" onClick={closeMobileSidebarMenu}>
        <SidebarLink to="/">
          <LayoutDashboardIcon /> {t('dashboard')}
        </SidebarLink>
        <TenantSidebarLink />
        <SidebarLink to="/estates">
          <HomeIcon /> <p>{t('estates')}</p>
        </SidebarLink>
        {pathname.startsWith('/estates') && (
          <SidebarLink to="/estates/me" className="ml-5" inactiveProps={{ className: 'ml-5' }}>
            <FolderUpIcon /> <p>{t('myEstates')}</p>
          </SidebarLink>
        )}
        <SidebarLink to="/profile">
          <User2Icon /> <p>{t('profile')}</p>
        </SidebarLink>
      </nav>
    </aside>
  );
};
