import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { getDigitalTwinsOptions } from '../../api/get-digital-twins';
import { useUpdateDigitalTwin } from '../../api/upload-digital-twins';

export interface UpdateDigiTwinModalProps {
  estateId: string;
  id: string;
  name?: string;
}

export const UpdateDigiTwinModal: React.FC<UpdateDigiTwinModalProps> = ({ id, estateId, name }) => {
  const [newName, setNewName] = useState(name);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: updateDigitalTwin } = useUpdateDigitalTwin({
    mutationConfig: {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: getDigitalTwinsOptions(estateId).queryKey }),
      onError: () => toast.error(t('auth-error')),
    },
  });

  const handleSave = () => {
    if (!newName) return;
    updateDigitalTwin({ id, name: newName });
    closeModal();
  };

  return (
    <ModalBase>
      <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
      <div className="mt-4 flex flex-row gap-2">
        <Button variant="contained" onClick={handleSave}>
          {t('save')}
        </Button>
        <Button variant="outline" onClick={closeModal}>
          {t('close')}
        </Button>
      </div>
    </ModalBase>
  );
};
