import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type Tenant = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.tenants>['get']>>['data']
>;

type TenantId = Tenant['id'];
export type SubTenant = Tenant['subTenants'][number];

type TenantQueryOptions = {
  queryConfig?: QueryConfig<typeof getTenant>;
  id: TenantId;
};

const getTenant = (id: TenantId) => {
  return extractData(api.tenants({ id }).get());
};

export const getTenantOptions = (id: TenantId) => ({
  queryKey: ['tenants', id],
  queryFn: () => getTenant(id),
});

export const getTenantQueryOptions = (id: TenantId) => {
  return queryOptions(getTenantOptions(id));
};

export const ensureTenantQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getTenantQueryOptions(params.tenantId));
};

export const useTenant = ({ queryConfig, id }: TenantQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getTenantQueryOptions(id) });
};
