import { z } from 'zod';

import { i18n } from '@/lib/i18n';

const MIN_REQUIRED_FIELD_VALUE_LENGTH = 1;

const getRequiredStringFiledSchema = (field: string) =>
  z.string().min(MIN_REQUIRED_FIELD_VALUE_LENGTH, i18n.t('required-field', { field }));

export const getCreateSubTenantSchema = () =>
  z.object({
    tenantData: z.object({
      name: getRequiredStringFiledSchema(i18n.t('name')),
      appName: z.string().optional(),
      legalName: z.string().optional(),
    }),
    addressData: z.object({
      addressAutoComplete: z.string().optional(),
      address: getRequiredStringFiledSchema(i18n.t('address')),
      postalCode: getRequiredStringFiledSchema(i18n.t('postalCode')),
      city: getRequiredStringFiledSchema(i18n.t('city')),
      countryId: getRequiredStringFiledSchema(i18n.t('city')),
      lat: z.number().optional(),
      long: z.number().optional(),
    }),
  });

export type CreateSubTenantType = z.infer<ReturnType<typeof getCreateSubTenantSchema>>;
