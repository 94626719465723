import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

import { EmployeeRoleType } from '../schema/employee-role';
import { Tenant } from './tenant';

type CreateUserTokenOptions = {
  mutationConfig?: MutationConfig<typeof createUserToken>;
};

type CreateUserToken = {
  tenantId: Tenant['id'];
  email: string;
  type: 'contact' | 'employee';
  role: EmployeeRoleType['role'] | 'client';
};

const createUserToken = (args: CreateUserToken) => {
  return extractData(api['invite-tokens'].post(args));
};

export const useCreateUserToken = ({ mutationConfig }: CreateUserTokenOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: createUserToken });
};

type DeleteUserTokenOptions = {
  mutationConfig?: MutationConfig<typeof deleteUserToken>;
};

type DeleteUserToken = {
  tenantId: Tenant['id'];
  id: string;
};

const deleteUserToken = ({ tenantId, id }: DeleteUserToken) => {
  return extractData(api['invite-tokens']({ id }).delete({ tenantId }));
};

export const useDeleteUserToken = ({ mutationConfig }: DeleteUserTokenOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: deleteUserToken });
};
