// TODO: get type from param
export const getDate = (date: string | number | Date | null | undefined) => {
  const newDate = date
    ? new Date(date)
        .toLocaleDateString()
        .split('/')
        .map((n) => (n.length < 2 ? `0${n}` : n))
        .join('.')
    : '--.--.----';

  return newDate;
};
