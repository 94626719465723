import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TrashIcon } from 'lucide-react';

import { openModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { queryClient } from '@/lib/react-query';

import { useDeleteSubTenant } from '../api/subtenants';
import { getTenantOptions } from '../api/tenant';

interface DeleteSubTenantButtonProps {
  id: string;
  name: string;
  tenantId: string;
}

export const DeleteSubTenantButton = ({ id, tenantId, name }: DeleteSubTenantButtonProps) => {
  const { t } = useTranslation();

  const { mutate } = useDeleteSubTenant({
    mutationConfig: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: getTenantOptions(tenantId).queryKey });
        toast.success(t('successfully-deleted-contact'));
      },
      onError: (error) => {
        toast.error(error.message);
      },
    },
  });

  const handleDelete = () => {
    mutate({ tenantId, subTenantId: id });
  };

  const handleConfirmDelete = () => {
    openModal('confirmation-modal', {
      title: t('deleteSubtenant'),
      description: t('confirmDeleteSubtenant', { name }),
      confirmationText: t('delete'),
      onSubmit: () => handleDelete(),
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="destructive">
          <TrashIcon aria-hidden />
          <span className="sr-only">{t('delete')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Button className="w-full text-white" onClick={handleConfirmDelete} variant="destructive">
            <span>{t('delete')}</span>
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
