import React from 'react';

import { CircleX } from 'lucide-react';

import { closeModal } from '@/components/modals/modals.utils';
import { ImageCarousel } from '@/components/ui/image-carousel';

import { ModalBase } from '../../../components/modals/modals-base';

export type ViewMediaModalType = {
  files: File[];
  fileName: string;
};

export interface ViewMediaModalProps {
  images: {
    creatorId: string;
    deletedAt: string | null;
    estateId: string;
    fileName: string;
    name: string;
    userId: string | null;
    id: string;
    createdAt: string | null;
    mediaId: string | null;
    updatedAt: string | null;
    fileType: string | null;
  }[];
  preselectedCurrentIndex: number;
}

export const ViewMediaModal: React.FC<ViewMediaModalProps> = (props) => {
  return (
    <ModalBase className="flex max-w-4xl flex-col overflow-hidden max-md:p-3">
      <CircleX
        onClick={closeModal}
        className="m-2 ml-auto cursor-pointer max-md:mt-0 [&_*]:stroke-primary"
        width={35}
        height={35}
      />
      <div className="h-full overflow-hidden">
        <ImageCarousel {...props} />
      </div>
    </ModalBase>
  );
};
