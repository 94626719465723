'use client';

import * as React from 'react';

import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const toggleVariants = cva(
  'inline-flex w-fit items-center justify-center rounded-sm bg-transparent text-base transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:size-6 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        md: 'h-[41px] px-[12px] py-[8.5px]',
        sm: 'h-[36px] px-[12px] py-[6px]',
        lg: 'h-[49px] px-[14px] py-[7px]',
      },
      color: {
        primary:
          'data-[state=on]:bg-primary data-[state=off]:text-primary data-[state=on]:text-primary-lighter',
        'primary-light':
          'text-primary-lighter data-[state=on]:bg-primary-light data-[state=off]:text-primary-light data-[state=on]:text-primary-lighter',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      color: 'primary-light',
    },
  }
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, color, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className, color }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
