import { useTranslation } from 'react-i18next';

import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { formatDate } from '@/utils/formatDate';

import { TenantTokens } from '../api/tenant-users';
import { DeleteTokenButton } from './delete-token-button';

interface OutstandingRequestListProps {
  requests: TenantTokens;
  tenantId: string;
}

export const OutstandingRequestList = ({ requests, tenantId }: OutstandingRequestListProps) => {
  const { t } = useTranslation();

  return (
    <div className="size-full">
      <Card className="px-4 py-6 lg:px-10">
        <div className="flex justify-between">
          <div>
            <h2 className="text-md text-primary-dark">{t('outstanding-requests')}</h2>
          </div>
        </div>
        {requests.length ? (
          <Table className="my-5 w-full table-auto bg-transparent">
            <TableHeader className="h-11 max-sm:hidden">
              <TableRow className="w-full justify-between">
                <TableHead className="text-left text-text-secondary">{t('email')}</TableHead>
                <TableHead className="text-left text-text-secondary max-md:hidden">
                  {t('created-at')}
                </TableHead>
                <TableHead className="text-center text-text-secondary">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {requests.map((request) => (
                <TableRow key={request.id} className="items-center text-primary-dark">
                  <TableCell className="w-2/5 whitespace-normal break-words py-5 text-left">
                    {request.email}
                  </TableCell>
                  <TableCell className="w-2/5 whitespace-normal break-words py-5 text-left">
                    {formatDate(request.createdAt)}
                  </TableCell>
                  <TableCell className="flex justify-end gap-2">
                    <DeleteTokenButton tenantId={tenantId} id={request.id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex justify-center py-16">
            <span>{t('no-outstanding-requests')}</span>
          </div>
        )}
      </Card>
    </div>
  );
};
