import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

import { TenantContacts } from './tenant-users';

type DeleteContactOptions = {
  mutationConfig?: MutationConfig<typeof deleteContact>;
};

type UpdateLastContactOptions = {
  mutationConfig?: MutationConfig<typeof updateLastContact>;
};

type UpdateOrDeleteContact = {
  contactId: TenantContacts[number]['id'];
};

const updateLastContact = ({ contactId }: UpdateOrDeleteContact) => {
  return extractData(api.contacts({ id: contactId }).lastContact.put());
};

export const useUpdateLastContact = ({ mutationConfig }: UpdateLastContactOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: updateLastContact });
};

const deleteContact = ({ contactId }: UpdateOrDeleteContact) => {
  return extractData(api.contacts({ id: contactId }).delete());
};

export const useDeleteContact = ({ mutationConfig }: DeleteContactOptions = {}) => {
  return useMutation({ ...mutationConfig, mutationFn: deleteContact });
};
