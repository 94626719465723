import { useTranslation } from 'react-i18next';

import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { ButtonGroup } from '@/components/ui/button-group';
import { SelectOption } from '@/components/ui/select';
import { Toolbar } from '@/features/estates/components/toolbar';
import { useTenant } from '@/features/tenants/api/tenant';
import { SubTenantSection } from '@/features/tenants/components/subtenant-section';
import { TenantEstateSection } from '@/features/tenants/components/tenant-estate-section';
import { TenantUsersSection } from '@/features/tenants/components/tenant-users-section';

export const TenantSection = ({
  id,
  active,
}: {
  id: string;
  active: 'estates' | 'users' | 'subtenants';
}) => {
  const { data } = useTenant({ id });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const route = getRouteApi(`/_authenticated/_sidebar-layout/tenant/$tenantId/${active}`);
  const possibleCreators = data.employees.map(({ user }) => ({
    title: `${user.firstName} ${user.lastName}`,
    id: `${user.firstName} ${user.lastName}`,
  })) satisfies SelectOption[];

  return (
    <>
      <section className="mb-4 flex flex-col gap-4 text-center">
        <h2 className="text-3xl font-semibold">{data.name}</h2>
        <div className="flex flex-row justify-between">
          <ButtonGroup>
            <Button
              onClick={() =>
                navigate({ to: '/tenant/$tenantId/estates', params: { tenantId: id } })
              }
              variant={active === 'estates' ? 'contained' : 'outline'}
            >
              {t('estates')}
            </Button>
            <Button
              onClick={() => navigate({ to: '/tenant/$tenantId/users', params: { tenantId: id } })}
              variant={active === 'users' ? 'contained' : 'outline'}
            >
              {t('users')}
            </Button>
            <Button
              onClick={() =>
                navigate({ to: '/tenant/$tenantId/subtenants', params: { tenantId: id } })
              }
              variant={active === 'subtenants' ? 'contained' : 'outline'}
            >
              {t('subtenants')}
            </Button>
          </ButtonGroup>
        </div>
      </section>
      {active === 'estates' ? (
        <section className="flex flex-col gap-[37px]">
          <Toolbar creatorList={possibleCreators} route={route} />
          <TenantEstateSection />
        </section>
      ) : active === 'users' ? (
        <TenantUsersSection />
      ) : active === 'subtenants' ? (
        <SubTenantSection />
      ) : null}
    </>
  );
};
