import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

import { EmployeeRoleType } from '../schema/employee-role';
import { Tenant } from './tenant';
import { TenantEmployees } from './tenant-users';

type DeleteEmployeeOptions = {
  mutationConfig?: MutationConfig<typeof deleteEmployee>;
};

type UpdateLastEmployeeOptions = {
  mutationConfig?: MutationConfig<typeof updateEmployeeRole>;
};

type DeleteEmployee = {
  tenantId: Tenant['id'];
  employeeUserId: TenantEmployees[number]['user']['id'];
};

type UpdateEmployeeRole = DeleteEmployee & EmployeeRoleType;

const updateEmployeeRole = ({ employeeUserId, tenantId, role }: UpdateEmployeeRole) => {
  return extractData(api.tenants({ id: tenantId }).employees({ employeeUserId }).put({ role }));
};

export const useUpdateEmployeeRole = ({ mutationConfig }: UpdateLastEmployeeOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: updateEmployeeRole });
};

const deleteEmployee = ({ employeeUserId, tenantId }: DeleteEmployee) => {
  return extractData(api.tenants({ id: tenantId }).employees({ employeeUserId }).delete());
};

export const useDeleteEmployee = ({ mutationConfig }: DeleteEmployeeOptions = {}) => {
  return useMutation({ ...mutationConfig, mutationFn: deleteEmployee });
};
