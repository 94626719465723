import { Link } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import { EstateImage } from '@/features/estates-property/components/estate-image';

import { EstateActions } from './estate-actions';
import { EstateAdditionalExtendedInfoProps, EstateExtendedInfo } from './estate-extended-info';
import { EstateCardProps } from './estate-grid-card';

export type EstateLongCardProps = EstateCardProps &
  EstateAdditionalExtendedInfoProps & { thumbnailId: string };

export const EstatesListCard = ({ ...props }: EstateLongCardProps) => {
  return (
    <Link to="/estates/$estateId" params={{ estateId: props.id }}>
      <Card className="grid grid-cols-[200px,auto]" role="listitem">
        <div className="flex size-full overflow-hidden">
          <EstateImage
            className="size-full"
            {...(props.thumbnailId && { thumbnailId: props.thumbnailId })}
          />
        </div>
        <div className="flex items-start">
          <EstateExtendedInfo className="flex w-full justify-center pl-[49px]" {...props} />
          <EstateActions className="mr-[31px] pt-3" />
        </div>
      </Card>
    </Link>
  );
};
