import { useTranslation } from 'react-i18next';

import { PenIcon } from 'lucide-react';

import { openModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import { EmployeeRoleType } from '../schema/employee-role';

interface EditEmployeeButtonProps extends EmployeeRoleType {
  id: string;
  tenantId: string;
}

export const EditEmployeeButton = ({ id, tenantId, role }: EditEmployeeButtonProps) => {
  const { t } = useTranslation();

  const onEditButtonClick = () =>
    openModal('edit-employee-role', { tenantId, employeeUserId: id, role });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="outline">
          <PenIcon aria-hidden />
          <span className="sr-only">{t('edit')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Button className="w-full" onClick={onEditButtonClick} variant="outline">
            <span>{t('edit-team-member-role')}</span>
          </Button>
        </DropdownMenuItem>
        {/* TODO: add more edit options */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
