import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectOption } from '@/components/ui/select';
import { getTenantUsersOptions } from '@/features/tenants/api/tenant-users';
import { employeeRoles } from '@/features/tenants/schema/employee-role';

import { useCreateUserToken } from '../../api/user-token';
import { AddEmployeeType, getAddEmployeeSchema } from '../../schema/add-employee';

export interface AddEmployeeModalProps {
  tenantId: string;
}

export const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({ tenantId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate } = useCreateUserToken({
    mutationConfig: {
      onSuccess: () => {
        toast.success(t('successfully-invited-team-member'));
        queryClient.invalidateQueries({ queryKey: getTenantUsersOptions(tenantId).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const roleOptions = employeeRoles.map((r) => ({
    title: t(r.replaceAll(' ', '-')),
    id: r,
  })) satisfies SelectOption[];

  const schema = getAddEmployeeSchema();
  const form = useForm<AddEmployeeType>({
    resolver: zodResolver(schema),
    defaultValues: {
      role: 'agent',
      email: '',
    },
  });

  const onSubmit = (data: AddEmployeeType) => {
    mutate({
      ...data,
      tenantId,
      type: 'employee',
    });
    closeModal();
  };

  return (
    <ModalBase className="min-w-[360px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={form.control}
            render={({ field, formState }) => (
              <Input
                {...field}
                label={t('email')}
                type="email"
                errors={[formState.errors.email?.message || '']}
              />
            )}
          />
          <Controller
            name="role"
            control={form.control}
            render={({ field, formState }) => (
              <Select
                {...field}
                label={t('role')}
                options={roleOptions}
                errors={[formState.errors.role?.message || '']}
              />
            )}
          />
          <div className="mt-4 flex flex-row gap-2">
            <Button variant="contained" type="submit">
              {t('submit')}
            </Button>
            <Button variant="outline" onClick={closeModal}>
              {t('close')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </ModalBase>
  );
};
