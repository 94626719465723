import { useTranslation } from 'react-i18next';

import { getRenderValue } from '@/utils/getEmptyVisualValue';

import { StatusType } from '../constants/status-type';

export type EstateInfoProps = {
  id: string;
  name: string | null;
  creator: string | null;
  livingArea?: number | null;
  roomsCount?: number | null;
  price: string;
  status: string;
  address?: {
    postalCode?: string | null;
    city?: string | null;
  } | null;
};

export const EstateInfo = ({
  address,
  name,
  livingArea,
  roomsCount,
  price,
  status,
  creator,
}: EstateInfoProps) => {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col text-nowrap px-[37px] pb-[30px] pt-[25px]">
      <div className="grid grid-cols-2 gap-1 text-sm text-text-secondary">
        <span className="truncate">
          {getRenderValue(`${address?.postalCode} ${address?.city}`)}
        </span>

        <span className="truncate">{getRenderValue(creator)}</span>
        {/* <div className="flex items-center gap-1">
          <span>{t('external id')}:</span>
          <span className="truncate">{getRenderValue(id)}</span>
        </div> */}
      </div>

      <div className="mt-1 flex items-center">
        <span className="flex-1 truncate text-md">{getRenderValue(name)}</span>
        {status === StatusType.draft && (
          <div className="rounded-lg bg-primary-dark px-6 py-1 text-white">{t('draft')}</div>
        )}
      </div>
      <div className="mt-3 flex w-full items-center justify-between border-t-2 border-t-background-lighter pt-3 text-sm">
        <div className="mb-auto flex flex-col">
          <span className="truncate text-text-secondary">{t('living area')}:</span>
          {livingArea ? (
            <span className="truncate">
              {livingArea} m<sup>2</sup>
            </span>
          ) : null}
        </div>
        <div className="mb-auto flex flex-col">
          <span className="truncate text-text-secondary">{t('rooms')}:</span>
          {roomsCount ? <span className="truncate">{roomsCount}</span> : null}
        </div>
        <div className="mb-auto flex flex-col">
          <span className="truncate text-text-secondary">{t('price')}:</span>
          {price ? <span className="truncate">{price}</span> : null}
        </div>
      </div>
    </div>
  );
};
