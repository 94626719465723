import { t } from 'i18next';

import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { formatDate } from '@/utils/formatDate';

import { TenantContacts } from '../api/tenant-users';
import { AddContactButton } from './add-contact-button';
import { DeleteContactButton } from './delete-contact-button';
import { EditContactButton } from './edit-contact-button';

interface TenantContactListProps {
  contacts: TenantContacts;
  tenantId: string;
}

export const TenantContactList = ({ contacts, tenantId }: TenantContactListProps) => {
  return (
    <div className="size-full">
      <Card className="px-4 py-6 lg:px-10">
        <div className="flex justify-between">
          <div>
            <h2 className="text-md text-primary-dark">{t('contacts')}</h2>
          </div>
          <AddContactButton tenantId={tenantId} />
        </div>
        {contacts.length ? (
          <Table className="my-5 w-full table-auto bg-transparent">
            <TableHeader className="h-11 max-sm:hidden">
              <TableRow className="w-full justify-between">
                <TableHead className="text-center text-text-secondary">{t('name')}</TableHead>
                <TableHead className="text-center text-text-secondary">{t('email')}</TableHead>
                <TableHead className="text-center text-text-secondary">{t('created-at')}</TableHead>
                <TableHead className="text-center text-text-secondary">
                  {t('last-contact')}
                </TableHead>
                <TableHead className="text-center text-text-secondary">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {contacts.map((contact) => (
                <TableRow
                  key={contact.id}
                  className="cursor-pointer items-center text-primary-dark"
                >
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center">
                    {`${contact.user.firstName} ${contact.user.lastName}`}
                  </TableCell>
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center">
                    {contact.user.email}
                  </TableCell>
                  <TableCell className="w-1/4 max-w-40 whitespace-normal break-words py-5 text-center max-sm:hidden">
                    {formatDate(contact.createdAt)}
                  </TableCell>
                  <TableCell className="max-w-40 whitespace-normal break-words py-5 text-center lg:w-1/4">
                    {contact.lastContact ? formatDate(contact.lastContact) : '-'}
                  </TableCell>
                  <TableCell className="flex max-w-40 gap-2 lg:w-1/4">
                    <EditContactButton id={contact.id} tenantId={tenantId} />
                    <DeleteContactButton id={contact.id} tenantId={tenantId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex justify-center py-16">
            <span>{t('no-contacts')}</span>
          </div>
        )}
      </Card>
    </div>
  );
};
