import { t } from 'i18next';

import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import { SubTenant } from '../api/tenant';
import { AddSubTenantButton } from './add-subtenant-button';
import { DeleteSubTenantButton } from './delete-subtenant-button';

interface SubTenantListProps {
  subTenants: SubTenant[];
  tenantId: string;
}

export const SubTenantList = ({ subTenants, tenantId }: SubTenantListProps) => {
  return (
    <div className="size-full">
      <Card className="px-4 py-6 lg:px-10">
        <div className="flex justify-between">
          <div>
            <h2 className="text-md text-primary-dark">{t('subtenants')}</h2>
          </div>
          <AddSubTenantButton tenantId={tenantId} />
        </div>
        {subTenants.length ? (
          <Table className="my-5 w-full table-auto bg-transparent">
            <TableHeader className="h-11 max-sm:hidden">
              <TableRow className="w-full justify-between">
                <TableHead className="text-center text-text-secondary">{t('name')}</TableHead>
                <TableHead className="text-center text-text-secondary">{t('address')}</TableHead>
                <TableHead className="text-center text-text-secondary">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {subTenants.map((subtenant) => (
                <TableRow
                  key={subtenant.id}
                  className="cursor-pointer items-center text-primary-dark"
                >
                  <TableCell className="w-1/3 max-w-40 whitespace-normal break-words py-5 text-center">
                    {subtenant.name}
                  </TableCell>
                  <TableCell className="w-1/3 max-w-40 whitespace-normal break-words py-5 text-center">
                    {subtenant.address ? subtenant.address.address : 'no address found'}
                  </TableCell>
                  <TableCell className="flex w-full justify-end gap-2">
                    <DeleteSubTenantButton
                      id={subtenant.id}
                      name={subtenant.name}
                      tenantId={tenantId}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex justify-center py-16">
            <span>{t('no-subtenants')}</span>
          </div>
        )}
      </Card>
    </div>
  );
};
