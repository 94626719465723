export const parseSearchParamsToSearchQuery = <
  T extends object = Record<string, string | object | boolean | undefined>,
>(
  args: T = {} as T
): T =>
  Object.entries(args).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        typeof value === 'object'
          ? Object.values(value).join('').trim().length
            ? parseSearchParamsToSearchQuery(value)
            : undefined
          : typeof value === 'boolean'
            ? value
            : !isNaN(value)
              ? Number(value)
              : value?.length
                ? value
                : undefined,
    }),
    {} as T
  );
