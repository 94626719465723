import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

type duplicateDigiTwinOptions = {
  mutationConfig?: MutationConfig<typeof duplicateDigiTwin>;
};

const duplicateDigiTwin = ({ id, name }: { id: string; name: string } & { id: string }) => {
  return extractData(api['digital-twins']({ id }).duplicate.post({ name }));
};

export const useDuplicateDigitalTwin = ({ mutationConfig }: duplicateDigiTwinOptions = {}) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: duplicateDigiTwin,
  });
};
