import { useTranslation } from 'react-i18next';

import { Building2Icon } from 'lucide-react';

import { useMyTenants } from '@/api/tenant';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { SidebarLink } from '../ui/sidebar-link';

export const TenantSidebarLink = () => {
  const { data } = useMyTenants();
  const { t } = useTranslation();

  if (!data || !data.length) {
    // don't show for users that are not connected to any tenant
    return null;
  }

  return (
    <Accordion type="single">
      <AccordionItem value="tenant">
        <AccordionTrigger>
          <div className="flex h-[41px] items-center gap-[12px] rounded-md px-8 py-[10px] text-md text-primary-darker hover:bg-primary-darker/10 [&_svg]:size-6 [&_svg]:shrink-0 [&_svg]:text-primary">
            <Building2Icon /> {t('tenant')}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {data.map((tenant) => (
            <SidebarLink
              key={tenant.id}
              to="/tenant/$tenantId"
              params={{ tenantId: tenant.id }}
              className="ml-5"
              inactiveProps={{ className: 'ml-5' }}
            >
              <p>{tenant.name}</p>
            </SidebarLink>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
