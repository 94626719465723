import { queryOptions, useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { Estate } from '@/features/estates-property/api/estate';
import { MutationConfig, QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type DigitalTwin = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['digital-twins']['get']>>['data']
>['digitalTwins'][number];

export type RoomPlan = DigitalTwin['roomPlans'][number];
export type PresignedUrl = RoomPlan['presignedUrls'][number];

export type DigitalTwinQueryOptions = {
  queryConfig?: QueryConfig<typeof getDigitalTwin>;
  id: string;
};

export type DigitalTwinPresignedUrlsQueryOptions = {
  queryConfig?: QueryConfig<typeof getDigitalTwinPresignedUrls>;
  id: string;
};

export type DigitalTwinsQueryOptions = {
  queryConfig?: QueryConfig<typeof getDigitalTwins>;
  id: Estate['id'];
};

type ShareDigiTwinOptions = {
  mutationConfig?: MutationConfig<typeof shareDigiTwin>;
};

export const getDigitalTwins = (id: Estate['id']) => {
  return extractData(api.estates({ id })['digital-twins'].get());
};

export const getDigitalTwinsOptions = (id: Estate['id']) => ({
  queryKey: ['digital-twins', id],
  queryFn: () => getDigitalTwins(id),
});

export const getDigitalTwinsQueryOptions = (id: Estate['id']) => {
  return queryOptions(getDigitalTwinsOptions(id));
};

export const ensureDigitalTwinsQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getDigitalTwinsQueryOptions(params.estateId));
};

export const useDigitalTwins = ({ queryConfig, id }: DigitalTwinsQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getDigitalTwinsQueryOptions(id) });
};

export const getDigitalTwin = (id: string) => {
  return extractData(api['digital-twins']({ id }).get());
};

export const getDigitalTwinOptions = (id: string) => ({
  queryKey: ['digital-twin', id],
  queryFn: () => getDigitalTwin(id),
});

export const useDigitalTwin = ({ queryConfig, id }: DigitalTwinQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getDigitalTwinOptions(id) });
};

export type DigitalTwinsResponseType = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['digital-twins']['get']>>['data']
>;

const shareDigiTwin = ({
  id,
  planId,
  expiresIn,
}: {
  id: string;
  planId: string;
  expiresIn?: number;
}) => {
  return extractData(
    api['digital-twins']({ id })['room-plan']({ planId }).share.post({ expiresIn })
  );
};

export const useShareDigiTwin = ({ mutationConfig }: ShareDigiTwinOptions) => {
  return useMutation({
    ...mutationConfig,
    mutationFn: shareDigiTwin,
  });
};

export const getDigitalTwinPresignedUrls = (id: string) => {
  return extractData(api['digital-twins']({ id })['shared-links'].get());
};

export const getDigitalTwinPresignedUrlsOptions = (id: string) => ({
  queryKey: ['digital-twin', id, 'shared-links'],
  queryFn: () => getDigitalTwinPresignedUrls(id),
});

export const useGetDigitalTwinsPresignedUrls = ({
  queryConfig,
  id,
}: DigitalTwinPresignedUrlsQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getDigitalTwinPresignedUrlsOptions(id) });
};
