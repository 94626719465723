import { useTranslation } from 'react-i18next';

import { getPriceWithCurrency } from '@/utils/getPriceWithCurrency';

import { Estates } from '../api/estates';
import { EstatesGridCard } from './estate-grid-card';
import { EstatesInfiniteScroll } from './estates-infinite-scroll';

interface EstateGridProps {
  data: Estates;
  filters?: object;
  filterOwn?: boolean;
}

export const EstateGrid: React.FC<EstateGridProps> = ({ data, filters, filterOwn }) => {
  const { i18n } = useTranslation();

  return (
    <div
      className="mb-[30px] grid grid-cols-1 gap-[27px] lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
      role="grid"
    >
      {data.map((estate) => (
        <EstatesGridCard
          key={estate.id}
          creator={`${estate.creator?.firstName ?? ''} ${estate.creator?.lastName ?? ''}`}
          address={estate.address}
          status={estate.status}
          id={estate.id}
          thumbnailId={estate.thumbnail?.mediaId}
          livingArea={estate.estateAreasRooms?.livingArea}
          roomsCount={estate.estateAreasRooms?.totalNumberOfRooms}
          name={estate.name}
          price={getPriceWithCurrency(
            estate.prices?.buyingPrice,
            estate.prices?.currency,
            i18n.language
          )}
        />
      ))}
      <EstatesInfiniteScroll Card={EstatesGridCard} filters={filters} filterOwn={filterOwn} />
    </div>
  );
};
