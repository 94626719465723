import { useTranslation } from 'react-i18next';

import { getRouteApi } from '@tanstack/react-router';
import { SlidersHorizontalIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface FilterButtonProps {
  route: ReturnType<typeof getRouteApi>;
}

export const FilterButton = ({ route }: FilterButtonProps) => {
  const [t] = useTranslation();
  const navigate = route.useNavigate();
  const { enableFilters } = route.useSearch();

  const switchEnableFilters = () => {
    navigate({
      search: (prev) => ({ ...prev, enableFilters: !prev.enableFilters }),
      replace: true,
    });
  };

  return (
    <Button
      size="xl"
      variant="outline"
      className={cn(
        'flex justify-center gap-[5px] px-[20px]',
        enableFilters && 'bg-primary-light text-white'
      )}
      onClick={switchEnableFilters}
    >
      <SlidersHorizontalIcon
        className={cn(
          'flex items-center transition-transform motion-reduce:transition-none',
          !enableFilters && 'rotate-180'
        )}
      />
      <span className="block max-xl:hidden">{t('filter')}</span>
    </Button>
  );
};
