import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { getDate } from '@/utils/getDate';
import { getRenderValue } from '@/utils/getEmptyVisualValue';

import { StatusType } from '../constants/status-type';
import { EstateInfoProps } from './estate-info';

export type EstateAdditionalExtendedInfoProps = {
  creator: string;
  createdAt: string;
};

type EstateExtendedInfoProps = EstateInfoProps &
  EstateAdditionalExtendedInfoProps & {
    className: string;
  };

export const EstateExtendedInfo = ({
  name,
  livingArea,
  roomsCount,
  price,
  creator,
  createdAt,
  className,
  status,
  ...props
}: EstateExtendedInfoProps) => {
  const [t] = useTranslation();

  return (
    <div className={`flex flex-col ${className}`}>
      <div className="mr-3 flex items-center justify-between gap-3 pt-3">
        <Link to="/estates/$estateId" params={{ estateId: props.id }}>
          <span className="truncate text-md max-md:mb-2 max-sm:w-48">{name}</span>
        </Link>
        {status === StatusType.draft && (
          <div className="rounded-lg bg-primary-dark px-6 py-1 text-sm text-white">
            {t('draft')}
          </div>
        )}
      </div>
      <div className="my-2 grid xl:grid-cols-2">
        <div className="mt-1 grid grid-cols-2 gap-y-1">
          <span className="truncate text-text-secondary">{t('living area')}:</span>
          <span className="truncate">
            {getRenderValue(livingArea)} m<sup>2</sup>
          </span>
          <span className="truncate text-text-secondary">{t('rooms')}:</span>
          <span className="truncate">{getRenderValue(roomsCount)}</span>
          <span className="truncate text-text-secondary">{t('price')}:</span>
          <span className="truncate">{getRenderValue(price)}</span>
          {/* <span className="truncate text-text-secondary max-md:hidden">{t('external id')}:</span>
          <span className="truncate max-md:hidden">{getRenderValue(externalId)}</span> */}
        </div>
        <div className="mt-1 grid grid-cols-2 grid-rows-[min-content] gap-y-1">
          <Trans
            t={t}
            i18nKey="creator"
            components={{
              wrapper: <Fragment />,
              label: <span className="text-text-secondary" />,
              highlight: <span className="truncate !text-text-primary" />,
            }}
            values={{
              name: getRenderValue(creator),
            }}
          />
          <span className="truncate text-text-secondary">{t('creation date')}:</span>
          <span className="truncate">{getDate(createdAt)}</span>
        </div>
      </div>
    </div>
  );
};
