import { toast } from 'react-toastify';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUserWithEmailAndPassword, UserCredential } from 'firebase/auth';

import { auth } from '@/lib/firebase';
import { i18n } from '@/lib/i18n';
import { api, extractData } from '@/lib/treaty';

import { ProfileRegistrationSchema, RegistrationSchema } from '../schemas/credentials';

const createDbUserFn = (props: Parameters<typeof api.users.put>[0]) => {
  return extractData(api.users.put(props));
};

export const useSignUpDbOnly = () => {
  const queryClient = useQueryClient();
  const signupDbOnly = useMutation({
    mutationFn: (variables: ProfileRegistrationSchema & { id: string; email: string }) =>
      createDbUserFn({
        email: variables.email,
        id: variables.id,
        firstName: variables.firstName,
        lastName: variables.lastName,
        isTosAccepted: Number(variables.isTosAccepted),
        isPrivacyAccepted: Number(variables.isPrivacyAccepted),
      }),
    onError: () => toast.error(i18n.t('auth-error')),
    onSuccess: () => {
      queryClient.clear();
    },
  });

  return {
    signupDbOnly: signupDbOnly.mutate,
    isLoading: signupDbOnly.isPending,
    error: signupDbOnly.error,
  };
};

export const useSignUp = (token?: string) => {
  const createDbUser = useMutation({
    mutationFn: createDbUserFn,
  });

  const createUser = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      createUserWithEmailAndPassword(auth, email, password),
    onSuccess: (userCredential: UserCredential, variables: RegistrationSchema) => {
      createDbUser.mutate({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: userCredential.user.email!,
        id: userCredential.user.uid,
        firstName: variables.firstName,
        lastName: variables.lastName,
        isTosAccepted: Number(variables.isTosAccepted),
        isPrivacyAccepted: Number(variables.isPrivacyAccepted),
        token,
      });
    },
    onError: () => toast.error(i18n.t('auth-error')),
  });

  const signup = (props: RegistrationSchema) => {
    createUser.mutate(props);
  };

  return { signup, isLoading: createUser.isPending, error: createUser.error };
};
