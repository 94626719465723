import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useQueryClient } from '@tanstack/react-query';

import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Select, SelectOption } from '@/components/ui/select';
import { useUpdateEmployeeRole } from '@/features/tenants/api/tenant-employee';
import { getTenantUsersOptions } from '@/features/tenants/api/tenant-users';
import { employeeRoles, EmployeeRoleType } from '@/features/tenants/schema/employee-role';

export interface EditEmployeeRoleModalProps extends EmployeeRoleType {
  employeeUserId: string;
  tenantId: string;
}

export const EditEmployeeRoleModal: React.FC<EditEmployeeRoleModalProps> = ({
  employeeUserId,
  role,
  tenantId,
}) => {
  const [newRole, setNewRole] = useState(role);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate } = useUpdateEmployeeRole({
    mutationConfig: {
      onSuccess: () => {
        toast.success(t('successfully-updated-team-member'));
        queryClient.invalidateQueries({ queryKey: getTenantUsersOptions(tenantId).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const roleOptions = employeeRoles.map((r) => ({
    title: t(r.replaceAll(' ', '-')),
    id: r,
  })) satisfies SelectOption[];

  const handleSave = () => {
    mutate({
      employeeUserId,
      tenantId,
      role: newRole,
    });
    closeModal();
  };

  return (
    <ModalBase>
      <Select
        multiple={false}
        options={roleOptions}
        value={newRole}
        onChange={(event) =>
          // to catch the deletion of the select value
          setNewRole(event.target.value ? (event.target.value as typeof role) : newRole)
        }
      />
      <div className="mt-4 flex flex-row gap-2">
        <Button variant="contained" onClick={handleSave}>
          {t('save')}
        </Button>
        <Button variant="outline" onClick={closeModal}>
          {t('close')}
        </Button>
      </div>
    </ModalBase>
  );
};
