import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { Estate } from '@/features/estates-property/api/estate';
import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type MediaQueryOptions = {
  queryConfig?: QueryConfig<typeof getMedia>;
  id: Estate['id'];
};

export const getMedia = (id: Estate['id']) => {
  return extractData(api.estates({ id }).media.get());
};

export const getMediaOptions = (id: Estate['id']) => ({
  queryKey: ['media', id],
  queryFn: () => getMedia(id),
});

export const getMediaQueryOptions = (id: Estate['id']) => {
  return queryOptions(getMediaOptions(id));
};

export const ensureMediaQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getMediaQueryOptions(params.estateId));
};

export const useMedia = ({ queryConfig, id }: MediaQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getMediaQueryOptions(id) });
};

export type MediaResponseType = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.estates>['media']['get']>>['data']
>;

export type DigitalTwinsType = MediaResponseType['digitalTwins'];
export type ImagesType = MediaResponseType['images'];
export type SphericalImagesType = MediaResponseType['sphericalImages'];
export type FloorPlans = MediaResponseType['floorLayouts'];
export type FocusPointsType = MediaResponseType['pois'];

export type MediaDataContentType = ImagesType & Partial<Pick<FloorPlans, 'fileType'>>;
