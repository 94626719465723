import { useTranslation } from 'react-i18next';

import { getRouteApi, Link } from '@tanstack/react-router';
import { AlignJustifyIcon, LayoutGridIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';

type ViewSwitchProps = {
  route: ReturnType<typeof getRouteApi>;
  from: string;
};

export const ViewSwitch = ({ route, from }: ViewSwitchProps) => {
  const [t] = useTranslation();
  const { view } = route.useSearch();

  return (
    <Button size="xl" variant="outline" className="gap-[5px] px-[20px] max-xl:hidden" asChild>
      <Link
        from={from}
        search={(prev) => ({
          ...prev,
          view: prev.view === 'grid' ? 'list' : 'grid',
        })}
        replace
      >
        {view === 'grid' ? (
          <LayoutGridIcon className="mr-2 flex items-center" />
        ) : (
          <AlignJustifyIcon className="mr-2 flex items-center" />
        )}
        {t('view')}
      </Link>
    </Button>
  );
};
