import { useCallback, useRef, useState } from 'react';

type UseInfiniteScroll = <T extends Element = Element>(args: {
  loading: boolean;
  stopLoad: boolean;
  startPage?: number;
}) => {
  lastElementRef: (node: T) => void;
  resetPageNum: () => void;
  pageNum: number;
};

export const useInfiniteScroll: UseInfiniteScroll = ({ loading, stopLoad, startPage = 1 }) => {
  const [pageNum, setPageNum] = useState<number>(startPage);
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (!stopLoad && entries[0].isIntersecting && pageNum) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, pageNum, stopLoad]
  );

  const resetPageNum = useCallback(() => {
    setPageNum(startPage);
  }, [startPage]);

  return { lastElementRef, resetPageNum, pageNum };
};
