import { Link } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import { EstateImage } from '@/features/estates-property/components/estate-image';

import { EstateActions } from './estate-actions';
import { EstateInfo, EstateInfoProps } from './estate-info';

export type EstateCardProps = EstateInfoProps & { thumbnailId?: string | null };

export const EstatesGridCard = ({ ...props }: EstateCardProps) => {
  return (
    <Link to="/estates/$estateId" params={{ estateId: props.id }}>
      <Card className="h-full min-h-[522px]" role="gridcell">
        <div className="relative">
          <EstateImage {...(props.thumbnailId && { thumbnailId: props.thumbnailId })} />
          <EstateActions className="absolute bottom-[10px] right-[10px]" />
        </div>
        <EstateInfo {...props} />
      </Card>
    </Link>
  );
};
