import * as React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { CaretDownIcon, Cross1Icon } from '@radix-ui/react-icons';
import { cva, VariantProps } from 'class-variance-authority';

import { useClickOutside } from '@/hooks/use-click-outside';
import { cn } from '@/lib/utils';

import { Checkbox } from './checkbox';

const selectVariants = cva(
  'flex w-full rounded-xs bg-primary-lighter shadow-sm transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&_svg]:shrink-0',
  {
    variants: {
      color: {
        primary:
          '[--input-color:hsla(var(--primary-color))] [--input-focus-color:hsla(var(--primary-color-light))]',
        'primary-light':
          '[--input-color:hsla(var(--primary-color-light))] [--input-focus-color:hsla(var(--primary-color))]',
      },
      variant: {
        default: 'border-[--input-color] text-[--input-color] placeholder:text-[--input-color]',
      },
      size: {
        xs: 'h-[24px] rounded-md text-sm font-medium [&>button]:px-[10px]',
        sm: 'h-[39px] rounded-md p-2 text-sm font-medium placeholder:font-normal',
        md: 'h-[49px] border text-sm [&>button]:px-4 [&_svg]:size-[20px]', //px-4
        lg: 'h-[57px] border text-md [&>button]:px-6 [&_svg]:size-[28px]', //px-6
      },
    },
    defaultVariants: {
      color: 'primary-light',
      variant: 'default',
      size: 'lg',
    },
  }
);

export interface SelectOption {
  title: string;
  id: string;
}

export type ChangeFunction<TValue = string | string[]> = (event: {
  target: { value: TValue; name?: string };
}) => void;

interface SelectPropsBase
  extends Omit<
      React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      'value' | 'onChange' | 'size' | 'color' | 'multiple'
    >,
    VariantProps<typeof selectVariants> {
  className?: string;
  errors?: string[];
  options: SelectOption[];
  label?: string;
  disableClear?: boolean;
  wrapperClassName?: HTMLDivElement['className'];
  readOnlySearchInput?: boolean;
}

export interface MultipleSelectProps extends SelectPropsBase {
  multiple: true;
  value: string[];
  disableCloseOnSelect?: undefined;
  onChange: ChangeFunction<string[]>;
}

export interface SingleSelectProps extends SelectPropsBase {
  multiple?: false | undefined;
  value: string | undefined;
  disableCloseOnSelect?: boolean;
  onChange: ChangeFunction<string>;
}

export type SelectProps = MultipleSelectProps | SingleSelectProps;

interface SelectOptionPops {
  id: string;
  title: string;
  isSelected: boolean;
  className?: string;
  onClick: (id: string) => void;
}

const SingleSelectOption: React.FC<SelectOptionPops> = ({
  id,
  title,
  isSelected,
  className,
  onClick,
}) => {
  const onClickHandler = () => onClick(id);
  return (
    <DropdownMenuPrimitive.Item
      className={cn(
        'relative flex w-full cursor-pointer select-none items-center gap-4 rounded-sm py-1.5 text-md text-primary outline-none transition-colors hover:bg-primary-light data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        isSelected && 'bg-primary text-primary-lighter',
        className
      )}
      onClick={onClickHandler}
    >
      <p className="px-2">{title}</p>
    </DropdownMenuPrimitive.Item>
  );
};

const MultipleSelectOption: React.FC<SelectOptionPops> = ({
  id,
  title,
  isSelected,
  className,
  onClick,
}) => {
  const onClickHandler = () => onClick(id);
  return (
    <DropdownMenuPrimitive.Item
      className={cn(
        'border-1 relative flex w-full cursor-pointer select-none items-center gap-2 rounded-sm border border-transparent px-2 py-1.5 text-md text-primary outline-none transition-colors hover:border-primary-light data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className
      )}
      onClick={onClickHandler}
    >
      <Checkbox checked={isSelected} />
      <p>{title}</p>
    </DropdownMenuPrimitive.Item>
  );
};

export const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      className,
      size,
      errors,
      label,
      placeholder,
      multiple,
      value,
      options,
      name,
      disableCloseOnSelect,
      onChange,
    },
    ref
  ) => {
    const [opened, setOpened] = React.useState(false);
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const [contentStyle, setContentStyle] = React.useState<React.CSSProperties>({});

    const openMenu = () => setOpened(true);

    const closeMenu = () => setOpened(false);

    const containerRef = useClickOutside<HTMLDivElement>(closeMenu, opened);

    React.useEffect(() => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setContentStyle({
          width: `${rect.width}px`,
          left: `${rect.left}px`,
          top: `${rect.bottom}px`,
        });
      }
    }, [opened]);

    const labeledValue = multiple
      ? value?.length
        ? `${
            options.filter(({ id }) => value?.includes(id))?.[0]?.title || ''
          } ${value.length > 1 ? `+${value?.length - 1}` : ''}`
        : ''
      : options.find(({ id }) => id === value)?.title || '';

    const onChangeHandler = (selectedValue: string) => {
      if (multiple) {
        const multipleValue = value?.includes(selectedValue)
          ? value?.filter((v) => v !== selectedValue)
          : [...value, selectedValue];
        setOpened(true);
        return onChange({
          target: {
            value: multipleValue,
            name,
          },
        });
      }
      if (!disableCloseOnSelect) closeMenu();
      return onChange({
        target: {
          value: selectedValue,
          name,
        },
      });
    };

    const onResetButtonClick = () => {
      closeMenu();
      if (multiple) {
        return onChange({
          target: {
            value: [],
            name,
          },
        });
      }
      return onChange({
        target: {
          value: '',
          name,
        },
      });
    };

    return (
      <div ref={wrapperRef} className="relative flex w-full flex-col gap-[10px] overflow-hidden">
        {label && <span className="select-none text-xs text-text-secondary">{label}</span>}
        <DropdownMenuPrimitive.Root open={opened}>
          <div
            className={selectVariants({
              className: cn(
                className,
                size,
                'flex size-full flex-row items-center justify-between gap-2 overflow-hidden'
              ),
            })}
          >
            <DropdownMenuPrimitive.Trigger
              ref={ref}
              onClick={openMenu}
              className={cn(
                'size-full overflow-hidden truncate text-left outline-none',
                !value?.length && 'font-extralight'
              )}
            >
              {labeledValue || placeholder}
            </DropdownMenuPrimitive.Trigger>
            {!!value?.length && (
              <Cross1Icon
                viewBox="-2 -2 20 20"
                className="cursor-pointer"
                onClick={onResetButtonClick}
              />
            )}
            {!value?.length && (
              <CaretDownIcon className={cn('transition', opened && 'rotate-180')} />
            )}
          </div>
          <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
              ref={containerRef}
              style={contentStyle}
              className="z-50 ml-9 mt-2 flex max-h-80 w-full flex-col gap-1 overflow-y-scroll rounded-md border border-gray-200 bg-white p-2 shadow-md"
            >
              {options.map(({ id, ...option }) => {
                const isSelected = multiple ? value.includes(id) : value === id;
                return multiple ? (
                  <MultipleSelectOption
                    {...option}
                    key={id}
                    id={id}
                    isSelected={isSelected}
                    onClick={onChangeHandler}
                  />
                ) : (
                  <SingleSelectOption
                    {...option}
                    key={id}
                    id={id}
                    isSelected={isSelected}
                    onClick={onChangeHandler}
                  />
                );
              })}
            </DropdownMenuPrimitive.Content>
          </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
        {errors?.map((error, index) => (
          <p
            key={error}
            role="alert"
            className="select-none overflow-auto text-xs text-danger opacity-0 motion-safe:animate-slide-in"
            style={{ '--delay': index * 0.1 + 's' } as React.CSSProperties}
          >
            {error}
          </p>
        ))}
      </div>
    );
  }
);
