import { useMutation } from '@tanstack/react-query';

import { MutationConfig } from '@/lib/react-query';
import { api, extractData } from '@/lib/treaty';

import { CreateSubTenantType } from '../schema/create-subtenant';

export type Tenant = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.tenants>['get']>>['data']
>;

type CreateSubTenantOptions = {
  mutationConfig?: MutationConfig<typeof createSubTenant>;
};

type DeleteSubTenantOptions = {
  mutationConfig?: MutationConfig<typeof deleteSubTenant>;
};

const createSubTenant = ({ tenantId: id, ...args }: CreateSubTenantType & { tenantId: string }) => {
  return extractData(api.tenants({ id })['sub-tenants'].post(args));
};

export const useCreateSubTenant = ({ mutationConfig }: CreateSubTenantOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: createSubTenant });
};

const deleteSubTenant = ({ tenantId, subTenantId }: { tenantId: string; subTenantId: string }) => {
  return extractData(api.tenants({ id: tenantId })['sub-tenants']({ subTenantId }).delete());
};

export const useDeleteSubTenant = ({ mutationConfig }: DeleteSubTenantOptions) => {
  return useMutation({ ...mutationConfig, mutationFn: deleteSubTenant });
};
