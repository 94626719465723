import React from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { ZodType } from 'zod';

type FormProps<T extends FieldValues> = Omit<React.ComponentProps<'form'>, 'onSubmit'> & {
  values?: T;
  defaultValues?: DefaultValues<T>;
  schema?: ZodType<T>;
  children: React.ReactNode;
  onSubmit?: SubmitHandler<T>;
  onInvalid?: SubmitErrorHandler<T>;
};

export const Form = <T extends FieldValues>({
  schema,
  defaultValues,
  children,
  onSubmit,
  onInvalid,
  values,
  ...props
}: FormProps<T>) => {
  const methods = useForm({
    values,
    defaultValues,
    resolver: schema ? zodResolver(schema) : undefined,
    shouldUseNativeValidation: false,
    mode: 'all',
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.stopPropagation();
    if (onSubmit) methods.handleSubmit(onSubmit, onInvalid)(event);
  };

  return (
    <FormProvider {...methods}>
      <form
        aria-errormessage={methods.formState.errors.root?.message}
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
};
