import { useTranslation } from 'react-i18next';

import { PlusIcon } from 'lucide-react';

import { openModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';

export const AddEmployeeButton = ({ tenantId }: { tenantId: string }) => {
  const { t } = useTranslation();
  return (
    <Button variant="outline" onClick={() => openModal('add-employee', { tenantId })}>
      <PlusIcon />
      <span className="sr-only md:not-sr-only">{t('add-team-member')}</span>
    </Button>
  );
};
