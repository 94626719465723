import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { Search } from 'lucide-react';

import { useCountriesOptions } from '@/api/countries';
import { closeModal } from '@/components/modals/modals.utils';
import { ModalBase } from '@/components/modals/modals-base';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Map } from '@/components/ui/map';
import { Select } from '@/components/ui/select';
import {
  AddressAutocomplete,
  type OnSelectSuggestion,
} from '@/features/estates/components/address-autocomplete';

import { useCreateSubTenant } from '../../api/subtenants';
import { getTenantOptions } from '../../api/tenant';
import { CreateSubTenantType, getCreateSubTenantSchema } from '../../schema/create-subtenant';

export interface AddSubtenantModalProps {
  tenantId: string;
}

export const AddSubtenantModal: React.FC<AddSubtenantModalProps> = ({ tenantId }) => {
  const { t } = useTranslation();
  const { data: countries } = useCountriesOptions();
  const queryClient = useQueryClient();

  const { mutate } = useCreateSubTenant({
    mutationConfig: {
      onSuccess: () => {
        // TODO: change message
        toast.success(t('successfully-invited-contact'));
        queryClient.invalidateQueries({ queryKey: getTenantOptions(tenantId).queryKey });
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  const schema = getCreateSubTenantSchema();
  const form = useForm<CreateSubTenantType>({
    resolver: zodResolver(schema),
  });

  const latitude = form.watch('addressData.lat');
  const longitude = form.watch('addressData.long');

  const onSelectSuggestion: OnSelectSuggestion = ({
    city,
    address,
    latitude,
    longitude,
    countryId,
    postalCode,
    countryCode,
  }) => {
    form.reset((prev) => ({
      ...prev,
      addressData: {
        ...prev.addressData,
        ...(address && { addressAutoComplete: address }),
        city: city ?? '',
        postalCode: postalCode ?? '',
        ...(address && { address }),
        ...(countryId && { countryId }),
        ...(latitude && { lat: latitude }),
        ...(longitude && { long: longitude }),
        ...(countryCode && { countryCode }),
      },
    }));
  };

  const onSubmit = (data: CreateSubTenantType) => {
    mutate({
      ...data,
      tenantId,
    });
    closeModal();
  };

  return (
    <ModalBase className="min-w-[360px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="w-full rounded-md border border-background-lighter">
            <p className="w-full rounded-t-md bg-background-lighter px-10 py-2">{t('general')}</p>
            <div className="px-10 pb-8 pt-5">
              <Controller
                name="tenantData.name"
                control={form.control}
                render={({ field, formState }) => (
                  <Input
                    {...field}
                    label={t('name')}
                    errors={[formState.errors.tenantData?.name?.message || '']}
                  />
                )}
              />
              {/* TODO: add more fields if necessary */}
            </div>
          </div>

          <div className="z-50 w-full rounded-md border border-background-lighter">
            <p className="w-full rounded-t-md bg-background-lighter px-10 py-2">{t('address')}</p>
            <div className="px-10 pb-8 pt-5">
              <Controller
                name="addressData.addressAutoComplete"
                control={form.control}
                render={({ field, formState }) => (
                  <AddressAutocomplete
                    {...field}
                    size="lg"
                    startIcon={<Search color="#9CA3AF" />}
                    label={t('address')}
                    onSelectSuggestion={onSelectSuggestion}
                    errors={[formState.errors.addressData?.addressAutoComplete?.message || '']}
                  />
                )}
              />
              <div className="mt-4 size-full h-48 overflow-hidden rounded bg-black">
                <Map
                  zoom={12}
                  {...(latitude &&
                    longitude && {
                      latitude: Number(latitude),
                      longitude: Number(longitude),
                    })}
                />
              </div>
              <div className="mt-5 grid grid-cols-2 gap-x-10">
                <Controller
                  name="addressData.address"
                  control={form.control}
                  render={({ field, formState }) => (
                    <Input
                      {...field}
                      size="lg"
                      label={t('street')}
                      errors={[formState.errors.addressData?.address?.message || '']}
                    />
                  )}
                />
                <Controller
                  name="addressData.postalCode"
                  control={form.control}
                  render={({ field, formState }) => (
                    <Input
                      {...field}
                      size="lg"
                      label={t('postalCode')}
                      errors={[formState.errors.addressData?.postalCode?.message || '']}
                    />
                  )}
                />
                <Controller
                  name="addressData.countryId"
                  control={form.control}
                  render={({ field, formState }) => (
                    <Select
                      {...field}
                      size="lg"
                      label={t('country')}
                      options={countries || []}
                      errors={[formState.errors.addressData?.countryId?.message || '']}
                    />
                  )}
                />
                <Controller
                  name="addressData.city"
                  control={form.control}
                  render={({ field, formState }) => (
                    <Input
                      {...field}
                      size="lg"
                      label={t('city')}
                      errors={[formState.errors.addressData?.city?.message || '']}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row gap-2">
            <Button variant="contained" type="submit">
              {t('submit')}
            </Button>
            <Button variant="outline" onClick={closeModal}>
              {t('close')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </ModalBase>
  );
};
