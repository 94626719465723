import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { PenIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { queryClient } from '@/lib/react-query';

import { useUpdateLastContact } from '../api/tenant-contact';
import { getTenantUsersOptions } from '../api/tenant-users';

interface EditContactButtonProps {
  id: string;
  tenantId: string;
}

export const EditContactButton = ({ id, tenantId }: EditContactButtonProps) => {
  const { t } = useTranslation();

  const { mutate: updateLastContact } = useUpdateLastContact({
    mutationConfig: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: getTenantUsersOptions(tenantId).queryKey });
        toast.success(t('successfully-updated-contact', { autoClose: 100 }));
      },
    },
  });

  const handleEdit = () => {
    updateLastContact({ contactId: id });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="outline">
          <PenIcon aria-hidden />
          <span className="sr-only">{t('edit')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Button className="w-full" onClick={handleEdit} variant="outline">
            <span>{t('update-last-contact')}</span>
          </Button>
        </DropdownMenuItem>
        {/* TODO: add more edit options */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
