export const parseObjectToSearchParams = <
  T extends object = Record<string, string | object | boolean | undefined>,
>(
  args: T
): T =>
  Object.entries(args).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        typeof value === 'object'
          ? Object.values(value).join('').trim().length
            ? parseObjectToSearchParams(value)
            : undefined
          : typeof value === 'boolean'
            ? value.toString() === 'true'
              ? value
              : undefined
            : value?.length
              ? value
              : undefined,
    }),
    {} as T
  );
