import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox, CheckboxProps } from '../ui/checkbox';

type FormCheckboxProps = {
  name: string;
} & CheckboxProps;

export const FormCheckbox = ({ name, ...props }: FormCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        return (
          <Checkbox
            checked={value}
            error={Boolean(errors[name]?.message)}
            onClick={() => onChange(!value)}
            {...props}
          />
        );
      }}
    />
  );
};
