import React, { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getRouteApi } from '@tanstack/react-router';
import { SearchIcon } from 'lucide-react';

import { useEstateOptions } from '@/api/estate-options';
import { Card } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Select, SelectOption } from '@/components/ui/select';
import { FilterButton } from '@/features/estates/components/filter-button';
import { ViewSwitch } from '@/features/estates/components/view-switch';
import { useDebouncedCallback } from '@/hooks/use-debounced-callback';
import { cn } from '@/lib/utils';
import { parseObjectToSearchParams } from '@/utils/parseSearchParams';

import { EstatesFiltersSchemaType } from '../schemas';

const additionalFeatures: (keyof Pick<
  EstatesFiltersSchemaType,
  'loggia' | 'balcony' | 'garden' | 'parkingSpace' | 'basement' | 'elevator'
>)[] = ['loggia', 'balcony', 'garden', 'parkingSpace', 'basement', 'elevator'];

const roomsOptions = [
  { id: '1', title: '1+' },
  { id: '2', title: '2+' },
  { id: '3', title: '3+' },
  { id: '4', title: '4+' },
  { id: '5', title: '5+' },
];

interface ToolbarProps {
  creatorList?: SelectOption[];
  route: ReturnType<typeof getRouteApi>;
}

export const Toolbar: FC<ToolbarProps> = ({ route, creatorList }) => {
  const { enableFilters, filters } = route.useSearch();
  const navigate = route.useNavigate();

  const [t] = useTranslation();

  const { data } = useEstateOptions();

  const methods = useForm<EstatesFiltersSchemaType>({
    defaultValues: {
      rooms: '',
      rentalPrices: {
        min: '',
        max: '',
      },
      buyingPrices: {
        min: '',
        max: '',
      },
      heatingType: '',
      flooring: '',
      glazing: '',
      loggia: false,
      balcony: false,
      garden: false,
      parkingSpace: false,
      basement: false,
      elevator: false,
      minLivingArea: '',
      usageType: '',
      marketType: '',
      searchString: '',
      propertyType: '',
    },
  });

  const { reset, watch } = methods;

  const updateSearchParams = (params: Record<string, string | (string | undefined)[]>) => {
    const filters = parseObjectToSearchParams<Partial<EstatesFiltersSchemaType>>(params);
    navigate({
      search: (prev) => ({ ...prev, filters, view: prev.view || 'grid' }),
    });
  };

  const debouncedUpdateSearchParamFn = useDebouncedCallback(updateSearchParams, 1000);

  React.useEffect(() => {
    reset((prev) => ({ ...prev, ...filters }));
    const sub = watch((data, { type }) => {
      if (type === 'change') {
        debouncedUpdateSearchParamFn(data);
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <Card className="rounded-[12px] p-3">
        <div className="grid grid-cols-[1fr_max-content_max-content_max-content_max-content] gap-1">
          <Controller
            {...methods}
            name="searchString"
            render={({ field }) => (
              <Input
                size="lg"
                placeholder={t('search')}
                startIcon={<SearchIcon className="text-primary-light" />}
                {...field}
              />
            )}
          />
          <Controller
            {...methods}
            name="minLivingArea"
            render={({ field }) => (
              <Input
                size="lg"
                type="number"
                className="pr-[30px] max-xl:hidden"
                placeholder={t('living area from')}
                endIcon={
                  <div className="absolute right-[16px] top-[17px] block text-md text-primary-light max-xl:hidden">
                    m<sup>2</sup>
                  </div>
                }
                {...field}
              />
            )}
          />
          <Controller
            {...methods}
            name="rooms"
            render={({ field }) => (
              <Select
                placeholder={t('numberOfRooms')}
                size="lg"
                className="block min-w-36 self-end max-xl:hidden"
                options={roomsOptions}
                {...field}
              />
            )}
          />
          <FilterButton route={route} />
          <ViewSwitch route={route} from={route.useMatch().pathname} />
        </div>
        <div
          className={cn(
            'transition-grid-template-rows grid duration-200 motion-reduce:transition-none',
            enableFilters ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'
          )}
        >
          <div className="overflow-hidden">
            <div className="mb-[20px] mt-[25px] border-t border-t-background-lighter px-[30px] pt-[20px]">
              <fieldset className="mb-[20px] grid grid-cols-4 gap-6 max-md:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2">
                <Controller
                  {...methods}
                  name="buyingPrices.min"
                  render={({ field }) => (
                    <Input type="number" label={t('buying-price-from')} size="lg" {...field} />
                  )}
                />
                <Controller
                  {...methods}
                  name="buyingPrices.max"
                  render={({ field }) => (
                    <Input type="number" label={t('buying-price-to')} size="lg" {...field} />
                  )}
                />
                <Controller
                  {...methods}
                  name="rentalPrices.min"
                  render={({ field }) => (
                    <Input type="number" label={t('rental-price-from')} size="lg" {...field} />
                  )}
                />
                <Controller
                  {...methods}
                  name="rentalPrices.max"
                  render={({ field }) => (
                    <Input type="number" label={t('rental-price-to')} size="lg" {...field} />
                  )}
                />
              </fieldset>
              <fieldset className="mb-[20px] grid grid-cols-4 gap-6 max-md:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2">
                <Controller
                  {...methods}
                  name="marketType"
                  render={({ field }) => (
                    <Select
                      label={t('marketingType')}
                      size="lg"
                      className="self-end"
                      options={data?.marketingType || []}
                      {...field}
                    />
                  )}
                />
                <Controller
                  {...methods}
                  name="usageType"
                  render={({ field }) => (
                    <Select
                      label={t('usage')}
                      size="lg"
                      className="self-end"
                      options={data?.usage || []}
                      {...field}
                    />
                  )}
                />
                <Controller
                  {...methods}
                  name="propertyType"
                  render={({ field }) => (
                    <Select
                      label={t('propertyType')}
                      size="lg"
                      className="self-end"
                      options={data?.propertyType || []}
                      {...field}
                    />
                  )}
                />
                <Controller
                  {...methods}
                  name="heatingType"
                  render={({ field }) => (
                    <Select
                      label={t('heatingType')}
                      size="lg"
                      className="self-end"
                      options={data?.heatingType || []}
                      {...field}
                    />
                  )}
                />
              </fieldset>
              <fieldset
                className={cn('mb-[20px] grid grid-cols-2 gap-6', creatorList && 'grid-cols-3')}
              >
                {creatorList ? (
                  <Controller
                    {...methods}
                    name="creatorId"
                    render={({ field }) => (
                      <Select
                        label={t('author')}
                        size="lg"
                        className="self-end"
                        options={creatorList}
                        {...field}
                      />
                    )}
                  />
                ) : null}
                <Controller
                  {...methods}
                  name="flooring"
                  render={({ field }) => (
                    <Select
                      label={t('flooring')}
                      size="lg"
                      className="self-end"
                      options={data?.flooring || []}
                      {...field}
                    />
                  )}
                />
                <Controller
                  {...methods}
                  name="glazing"
                  render={({ field }) => (
                    <Select
                      label={t('glazing')}
                      size="lg"
                      className="self-end"
                      options={data?.glazing || []}
                      {...field}
                    />
                  )}
                />
              </fieldset>
              <fieldset className="mb-[20px] grid grid-cols-1 gap-6">
                <Controller
                  {...methods}
                  name="minLivingArea"
                  render={({ field }) => (
                    <Input
                      size="lg"
                      type="number"
                      className="block pr-[30px] xl:hidden"
                      placeholder={t('living area from')}
                      endIcon={
                        <div className="absolute right-[16px] top-[17px] block text-md text-primary-light xl:hidden">
                          m<sup>2</sup>
                        </div>
                      }
                      {...field}
                    />
                  )}
                />
                <Controller
                  {...methods}
                  name="rooms"
                  render={({ field }) => (
                    <Select
                      placeholder={t('numberOfRooms')}
                      size="lg"
                      className="block min-w-36 self-end xl:hidden"
                      options={roomsOptions}
                      {...field}
                    />
                  )}
                />
              </fieldset>
              <fieldset className="mb-[20px] grid grid-cols-4 gap-6 max-md:grid-cols-2 max-sm:grid-cols-1 max-sm:gap-2">
                {additionalFeatures.map((fieldName) => (
                  <Controller
                    key={fieldName}
                    name={fieldName}
                    render={({ field: { value, onChange, ...field }, formState: { errors } }) => (
                      <Checkbox
                        checked={value}
                        label={t(fieldName)}
                        error={Boolean(errors[fieldName]?.message)}
                        onClick={() => onChange(!value)}
                        {...field}
                      />
                    )}
                    {...methods}
                  />
                ))}
              </fieldset>
            </div>
          </div>
        </div>
      </Card>
    </FormProvider>
  );
};
