import { getRouteApi } from '@tanstack/react-router';

import { useTenant } from '../api/tenant';
import { SubTenantList } from './subtenant-list';

const route = getRouteApi('/_authenticated/_sidebar-layout/tenant/$tenantId/subtenants');

export const SubTenantSection = () => {
  const { tenantId } = route.useParams();

  const { data } = useTenant({ id: tenantId });

  if (!data) return null;

  return (
    <section className="flex flex-col gap-4 pb-12">
      <SubTenantList tenantId={tenantId} subTenants={data.subTenants} />
    </section>
  );
};
