import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../ui/button';
import Spinner from '../ui/spinner';
import { closeModal } from './modals.utils';
import { ModalBase } from './modals-base';

export interface ConfirmationModalProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  confirmationText?: string;
  onClose?: () => void;
  onSubmit: () => Promise<void> | void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  confirmationText,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const closeModalHandler = () => {
    closeModal();
    return onClose?.();
  };
  const submitHandler = async () => {
    try {
      setLoading(true);
      closeModalHandler();
      return await onSubmit();
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalBase className="max-w-96">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-primary-lighter/40">
          <Spinner className="size-12 [&_*]:stroke-primary-dark" />
        </div>
      )}
      {typeof title === 'string' ? <h3 className="text-center text-md">{title}</h3> : title}
      {typeof title === 'string' ? (
        <p className="mt-6 text-center text-base text-text-secondary">{description}</p>
      ) : (
        description
      )}
      <div className="mt-8 grid grid-cols-2 gap-4">
        <Button onClick={closeModal} variant="outline" className="border-red-500 text-red-500">
          {t('cancel')}
        </Button>
        <Button onClick={submitHandler}>{confirmationText}</Button>
      </div>
    </ModalBase>
  );
};
