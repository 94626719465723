import { useTranslation } from 'react-i18next';

import { PlusIcon } from 'lucide-react';

import { openModal } from '@/components/modals/modals.utils';
import { Button } from '@/components/ui/button';

export const AddSubTenantButton = ({ tenantId }: { tenantId: string }) => {
  const { t } = useTranslation();
  return (
    <Button variant="outline" onClick={() => openModal('add-subtenant', { tenantId })}>
      <PlusIcon />
      <span className="sr-only md:not-sr-only">{t('add-subtenant')}</span>
    </Button>
  );
};
