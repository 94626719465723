import { z } from 'zod';

import { i18n } from '@/lib/i18n';

export const credentialsSchema = z.object({
  email: z.string().email(i18n.t('should be a valid email')),
  password: z.string().min(6, i18n.t('minimum password length 6 characters')),
});

export const emailSchema = credentialsSchema.pick({ email: true });

export const profileRegistrationSchema = z.object({
  firstName: z.string().min(1, { message: i18n.t('requireWarning') }),
  lastName: z.string().min(1, { message: i18n.t('requireWarning') }),
  isTosAccepted: z.boolean().refine((val) => val === true, {
    message: i18n.t('requireWarning'),
  }),
  isPrivacyAccepted: z.boolean().refine((val) => val === true, {
    message: i18n.t('requireWarning'),
  }),
});

export const registrationSchema = credentialsSchema.extend(profileRegistrationSchema.shape);

export type CredentialsSchema = z.infer<typeof credentialsSchema>;
export type EmailSchema = z.infer<typeof emailSchema>;
export type RegistrationSchema = z.infer<typeof registrationSchema>;
export type ProfileRegistrationSchema = z.infer<typeof profileRegistrationSchema>;
