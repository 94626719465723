import { useFormContext } from 'react-hook-form';

import { Input, InputProps } from '@/components/ui/input';

export type FormInputProps = Omit<InputProps, 'name'> & {
  name: string;
};

export const FormInput = ({ id, name, onChange, onBlur, ...props }: FormInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message;

  return (
    <Input
      id={id ?? name}
      {...props}
      {...register(name, {
        onChange,
        onBlur,
      })}
      errors={typeof error === 'string' ? [error] : undefined}
    />
  );
};
